'use client';

import Image from 'next/image';
import Link from 'next/link';
import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';

export default function Logo({ inverted = false }: { inverted?: boolean }) {
  const { resolvedTheme } = useTheme();
  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  if (!mounted)
    return (
      <span className="my-auto flex h-20 self-center text-2xl">
        <Image
          src="/logo.svg"
          width={150}
          height={80}
          quality={5}
          sizes="4vw"
          alt="Loading logo"
          priority
          title="Loading logo"
          className="m-auto h-[50px] w-auto"
        />
      </span>
    );

  return (
    <Link href="/" className="flex items-center">
      <div className="mt-4-auto flex h-20 self-center text-2xl">
        {resolvedTheme === 'dark' || inverted ? (
          <Image
            width={150}
            height={80}
            className="my-auto h-[50px] w-auto"
            src="/logo.svg"
            priority
            alt="lapki-pets"
          />
        ) : (
          <Image
            width={150}
            height={80}
            className="my-auto h-[50px] w-auto"
            src="/logo-light.svg"
            alt="lapki-pets"
            priority
          />
        )}
      </div>
    </Link>
  );
}

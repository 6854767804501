import { HTMLAttributes } from 'react';

interface Props {
  className?: HTMLAttributes<HTMLElement>['className'];
}

export default function Divider({ className }: Props) {
  return (
    <hr
      role="separator"
      className={`mt-4 h-0.5 border-t-0 bg-blue-main/35 opacity-100 dark:bg-slate-200 dark:opacity-50 ${className}`}
    />
  );
}

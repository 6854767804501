'use client';

import { useRouter } from 'next/navigation';

import { Icons } from '../../ui';

export default function BackButton() {
  const router = useRouter();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    router.back();
  };

  return (
    <button className="flex items-center px-4" aria-label="Вернуться назад" onClick={handleClick}>
      <Icons.LeftButtonArrow
        width={16}
        height={16}
        className="ml-[-0.5rem] mr-1 text-blue-main dark:text-white"
      />
      <span className="font-[500] text-blue-main dark:text-white">Назад</span>
    </button>
  );
}

const checkEnvironment = () => {
  const isDevBuild = process.env.NEXT_PUBLIC_BUILD_ENV === 'development';

  let base_url =
    process.env.NODE_ENV === 'development' || isDevBuild
      ? 'http://localhost:3000'
      : 'https://www.lapki-pets.by';

  return base_url;
};

export default checkEnvironment;

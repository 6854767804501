import { SVGProps } from 'react';
import './index.css';

function JuniorBreedChampion(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 120 120" {...props}>
      <g>
        <circle className="st0-jun-breed" cx="60" cy="83.7" r="23.3" />
        <circle className="st1-jun-breed" cx="60" cy="83.7" r="18.7" />
        <path
          className="st0-jun-breed"
          d="M60,54.4c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S62.2,54.4,60,54.4z M60,61.1c-1.5,0-2.6-1.2-2.6-2.6   s1.2-2.6,2.6-2.6s2.6,1.2,2.6,2.6S61.5,61.1,60,61.1z"
        />
        <path
          className="st2-jun-breed"
          d="M82.2,15.7v31.2c0,0.9-0.6,1.8-1.6,2.3l-11.9,5.6L61.7,58c-1,0.5-2.3,0.5-3.3,0l-6.9-3.3l-11.9-5.6   c-1-0.5-1.6-1.4-1.6-2.3V15.7c0-1.5,1.5-2.7,3.3-2.7h37.7C80.7,13,82.2,14.2,82.2,15.7z"
        />
        <path
          className="st3-jun-breed"
          d="M68.6,13v41.8L61.7,58c-1,0.5-2.3,0.5-3.3,0l-6.9-3.3V13H68.6z"
        />
      </g>
    </svg>
  );
}

function JuniorCountryChampion(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 120 120" {...props}>
      <g>
        <path
          className="st0-jun-country"
          d="M101,34l-0.2-1.7h-10c0.5-3.4,0.8-6.9,1-10.5c0.1-1.9-1.4-3.5-3.1-3.5H31.4c-1.8,0-3.2,1.6-3.1,3.5   c0.1,3.6,0.5,7.1,1,10.5h-10L19,34c-0.1,0.4-1.2,10.6,5.4,19.8c4.3,6,11,10.1,19.7,12.2c2.8,2.8,5.9,4.9,9.2,6.2   c-0.4,4.1-0.9,8.1-1.4,11.8h16.3c-0.6-3.8-1.1-7.7-1.5-11.8c3.3-1.2,6.4-3.3,9.2-6.2c8.7-2.1,15.4-6.2,19.7-12.2   C102.2,44.6,101,34.4,101,34z M27.3,51.3c-4.2-5.8-4.7-12.1-4.7-15.1h7.3c1.9,9.5,5.3,17.9,9.6,24.2C34.3,58.4,30.2,55.3,27.3,51.3   z M92.7,51.3c-2.9,4-7,7.1-12.2,9.1c4.4-6.4,7.7-14.7,9.6-24.2h7.3C97.4,39.2,96.8,45.5,92.7,51.3z"
        />
        <path
          className="st1-jun-country"
          d="M77,98.1H43c-1,0-1.8-0.8-1.8-1.8V83.5c0-1,0.8-1.8,1.8-1.8h34c1,0,1.8,0.8,1.8,1.8v12.8   C78.8,97.3,78,98.1,77,98.1z"
        />
        <path
          className="st2-jun-country"
          d="M37.9,101.9h44.2c1,0,1.8-0.8,1.8-1.8v-3.8c0-1-0.8-1.8-1.8-1.8H37.9c-1,0-1.8,0.8-1.8,1.8v3.8   C36.1,101,36.9,101.9,37.9,101.9z"
        />
        <path
          className="st0-jun-country"
          d="M68,91H52c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h16c0.7,0,1.2,0.5,1.2,1.2v2.5   C69.2,90.5,68.6,91,68,91z"
        />
      </g>
    </svg>
  );
}

function JuniorCountryGrandChampion(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <path
        style={{ fill: '#7E4F1F' }}
        d="M373.644,213.094H138.355c-28.557,0-52.756-21.368-56.29-49.705l-7.342-58.852
	c-2.012-16.136,3.006-32.381,13.768-44.57s26.263-19.179,42.523-19.179h249.972c16.26,0,31.76,6.99,42.523,19.179
	c10.763,12.19,15.782,28.435,13.768,44.57l-7.342,58.853C426.4,191.725,402.201,213.094,373.644,213.094z M131.014,79.634
	c-5.126,0-10.011,2.203-13.403,6.046c-3.392,3.841-4.974,8.963-4.34,14.048l7.342,58.852c1.114,8.931,8.742,15.668,17.743,15.668
	h235.289c9.001,0,16.629-6.735,17.743-15.668l7.342-58.852c0.634-5.086-0.948-10.206-4.341-14.049
	c-3.392-3.84-8.278-6.045-13.403-6.045C380.986,79.634,131.014,79.634,131.014,79.634z"
      />
      <path
        style={{ fill: '#C27526' }}
        d="M255.467,174.247H138.355c-9,0-16.629-6.735-17.743-15.668l-7.342-58.852
	c-0.634-5.087,0.948-10.207,4.34-14.048c3.393-3.841,8.278-6.046,13.403-6.046h124.453V40.787H131.014
	c-16.26,0-31.76,6.99-42.523,19.179C77.728,72.156,72.71,88.4,74.722,104.537l7.342,58.852c3.535,28.337,27.734,49.705,56.29,49.705
	h117.112L255.467,174.247L255.467,174.247z"
      />
      <path
        style={{ fill: '#DF871E' }}
        d="M356.72,32.737C356.72,14.685,342.035,0,323.983,0H188.017c-18.052,0-32.737,14.685-32.737,32.737
	v171.094c0,26.549,14.255,51.336,37.202,64.687c5.827,3.391,9.668,9.404,10.299,16.11l5.668,90.224
	c0.552,8.773,7.827,15.605,16.616,15.605h61.87c8.789,0,16.065-6.833,16.616-15.605l5.668-90.224
	c0.63-6.706,4.471-12.72,10.298-16.11c22.947-13.352,37.202-38.139,37.202-64.687L356.72,32.737L356.72,32.737z"
      />
      <path
        style={{ fill: '#F7DC4D' }}
        d="M255.467,0h-67.45c-18.052,0-32.737,14.685-32.737,32.737v171.094
	c0,26.549,14.255,51.336,37.202,64.687c5.827,3.391,9.668,9.404,10.299,16.11l5.668,90.224c0.552,8.773,7.827,15.605,16.616,15.605
	h30.403V0H255.467z"
      />
      <path
        style={{ fill: '#C27526' }}
        d="M251.514,79.895c0.729-1.522,2.266-2.49,3.953-2.49c1.687,0,3.224,0.968,3.954,2.49l13.792,28.796
	l31.648,4.219c1.673,0.223,3.069,1.386,3.589,2.99c0.522,1.605,0.075,3.366-1.147,4.53l-23.124,22.015l5.768,31.403
	c0.305,1.659-0.37,3.346-1.735,4.339c-1.365,0.992-3.178,1.112-4.662,0.31l-28.083-15.189l-28.083,15.189
	c-1.484,0.802-3.298,0.683-4.662-0.31c-1.365-0.992-2.04-2.679-1.735-4.339l5.768-31.403l-23.124-22.015
	c-1.222-1.163-1.668-2.925-1.147-4.53c0.522-1.605,1.918-2.768,3.589-2.99l31.648-4.219L251.514,79.895z"
      />
      <path
        style={{ fill: '#E5BF2B' }}
        d="M251.514,79.895l-13.792,28.796l-31.648,4.219c-1.673,0.223-3.069,1.386-3.589,2.99
	c-0.522,1.605-0.075,3.366,1.147,4.53l23.124,22.015l-5.768,31.403c-0.305,1.659,0.37,3.346,1.735,4.339
	c1.365,0.991,3.178,1.112,4.662,0.31l28.083-15.189V77.404C253.78,77.404,252.243,78.373,251.514,79.895z"
      />
      <path
        style={{ fill: '#004573' }}
        d="M348.965,414.257c0-30.6-24.895-55.496-55.496-55.496H218.53c-30.6,0-55.496,24.895-55.496,55.496
	c0,9.194,7.454,16.649,15.539,16.649h152.633C341.511,430.905,348.965,423.451,348.965,414.257z"
      />
      <path
        style={{ fill: '#0082D1' }}
        d="M255.467,358.761H218.53c-30.6,0-55.496,24.895-55.496,55.496c0,9.194,7.454,16.649,16.649,16.649
	h75.674L255.467,358.761z"
      />
      <path
        style={{ fill: '#3E3E42' }}
        d="M386.266,512c7.198,0,12.933-2.854,16.604-7.711c3.672-4.857,4.854-11.152,3.193-17.009
	l-22.198-78.32c-2.369-8.359-10-14.127-19.798-14.127H145.712c-7.577,0-15.209,5.768-17.578,14.127l-22.198,78.32
	c-1.66,5.857-0.478,12.154,3.193,17.009c3.672,4.856,9.407,7.711,14.384,7.711H386.266z"
      />
      <g>
        <path
          style={{ fill: '#797882' }}
          d="M255.358,394.833H146.823c-8.687,0-16.319,5.768-18.688,14.127l-22.198,78.32
		c-1.66,5.857-0.478,12.154,3.193,17.009c3.672,4.856,9.406,7.711,15.494,7.711h130.733V394.833z"
        />
        <path
          style={{ fill: '#797882' }}
          d="M334.271,471.323c0,5.977-4.845,10.822-11.932,10.822H190.312c-4.867,0-9.712-4.845-9.712-10.822
		v-34.962c0-5.977,4.845-10.822,9.712-10.822h132.027c7.087,0,11.932,4.845,11.932,10.822V471.323z"
        />
      </g>
      <path
        style={{ fill: '#C6C5CA' }}
        d="M255.358,482.144h-63.936c-5.977,0-10.822-4.845-10.822-10.822v-34.962
	c0-5.977,4.845-10.822,10.822-10.822h63.936V482.144z"
      />
    </svg>
  );
}

function BreedChampion(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 120 120" {...props}>
      <g>
        <polygon
          className="st0-breed"
          points="79.7,45.6 60,55.5 40.3,45.6 15.9,94.3 31.1,92.8 38.9,105.9 60,63.9 81.1,105.9 88.9,92.8    104.1,94.3  "
        />
        <circle className="st1-breed" cx="60" cy="46.4" r="32.2" />
        <circle className="st2-breed" cx="60" cy="46.4" r="25.3" />
        <path
          className="st3-breed"
          d="M61.2,31.2l4.2,8.4c0.2,0.4,0.6,0.7,1,0.8l9.3,1.4c1.1,0.2,1.6,1.5,0.8,2.3l-6.7,6.6c-0.3,0.3-0.5,0.8-0.4,1.2   l1.6,9.3c0.2,1.1-1,2-2,1.4l-8.3-4.4c-0.4-0.2-0.9-0.2-1.3,0L51,62.6c-1,0.5-2.2-0.3-2-1.4l1.6-9.3c0.1-0.4-0.1-0.9-0.4-1.2   l-6.7-6.6c-0.8-0.8-0.4-2.2,0.8-2.3l9.3-1.4c0.4-0.1,0.8-0.3,1-0.8l4.2-8.4C59.3,30.2,60.7,30.2,61.2,31.2z"
        />
      </g>
    </svg>
  );
}

function CountryChampion(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 512.009 512.009" {...props}>
      <g transform="translate(-1)">
        <path
          style={{ fill: '#EDDE64' }}
          d="M296.908,370.763h-79.81c-75.467,0-136.642-61.175-136.642-136.651V16.433
		c0-9.075,7.353-16.428,16.428-16.428h320.238c9.075,0,16.437,7.353,16.437,16.428v217.679
		C433.559,309.588,372.375,370.763,296.908,370.763"
        />
        <path
          style={{ fill: '#ECBA16' }}
          d="M296.91,344.28h-79.81c-61.775,0-110.168-46.53-110.168-105.931c0-4.873,3.955-8.828,8.828-8.828
		c4.882,0,8.828,3.955,8.828,8.828c0,49.496,40.642,88.276,92.513,88.276h79.81c51.871,0,92.504-38.78,92.504-88.276
		c0-4.873,3.955-8.828,8.828-8.828c4.882,0,8.828,3.955,8.828,8.828C407.069,297.75,358.685,344.28,296.91,344.28"
        />
        <path
          style={{ fill: '#EDDE64' }}
          d="M327.625,459.039H186.384v-18.264c0-9.419,7.627-17.046,17.046-17.046h107.149
		c9.419,0,17.046,7.627,17.046,17.046V459.039z"
        />
        <g>
          <path
            style={{ fill: '#ECBA16' }}
            d="M407.073,512.004H106.935c0-29.316,23.764-53.08,53.08-53.08h193.977
			C383.31,458.924,407.073,482.688,407.073,512.004"
          />
          <path
            style={{ fill: '#ECBA16' }}
            d="M470.169,70.625h-36.608v35.31h36.608c4.114,0,7.459,4.511,7.459,8.625v80.075
			c0,21.513-11.467,41.755-29.917,52.833l-16.252,9.754c-3.381,19.8-10.911,38.171-21.839,54.087l6.471-3.69l49.796-29.881
			c29.025-17.408,47.122-49.258,47.122-83.103V114.56C513.009,90.973,493.747,70.625,470.169,70.625"
          />
          <path
            style={{ fill: '#ECBA16' }}
            d="M296.908,370.763h-79.81c-1.43,0-2.816-0.168-4.228-0.212v53.177h88.276v-53.177
			C299.724,370.595,298.338,370.763,296.908,370.763"
          />
          <path
            style={{ fill: '#ECBA16' }}
            d="M417.125,0.004H96.887c-9.075,0-16.437,7.353-16.437,16.428V52.97h353.103V16.433
			C433.553,7.358,426.2,0.004,417.125,0.004"
          />
          <path
            style={{ fill: '#ECBA16' }}
            d="M257.004,97.108l23.208,44.138h47.413l-35.31,44.138l8.828,52.966l-44.138-25.768l-44.138,25.768
			l8.828-52.966l-35.31-44.138h47.413L257.004,97.108z"
          />
          <path
            style={{ fill: '#ECBA16' }}
            d="M43.84,70.625h36.608v35.31H43.84c-4.114,0-7.459,4.511-7.459,8.625v80.075
			c0,21.513,11.467,41.755,29.917,52.833l16.252,9.754c3.381,19.8,10.911,38.171,21.839,54.087l-6.471-3.69l-49.796-29.881
			C19.097,260.33,1,228.48,1,194.635V114.56C1,90.973,20.262,70.625,43.84,70.625"
          />
        </g>
      </g>
    </svg>
  );
}

function GrandCountryChampion(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <g>
        <path
          style={{ fill: '#ECBA16' }}
          d="M256,390.508c9.702,0,18.571,3.298,25.8,8.678h52.302l-29.184-20.506
		c-9.095-9.095-14.206-21.426-14.206-34.287V209.677c-10.639,4.651-22.363,7.272-34.712,7.272s-24.073-2.621-34.712-7.272v134.717
		c0,12.861-5.111,25.192-14.206,34.287l-29.184,20.506H230.2C237.429,393.806,246.298,390.508,256,390.508"
        />
        <g>
          <path
            style={{ fill: '#EDDE64' }}
            d="M169.22,8.678v121.492c0,47.928,38.851,86.78,86.78,86.78s86.78-38.851,86.78-86.78V8.678H169.22z"
          />
          <path
            style={{ fill: '#EDDE64' }}
            d="M308.068,347.119H203.932c-4.799,0-8.678-3.888-8.678-8.678c0-4.79,3.879-8.678,8.678-8.678
			h104.136c4.799,0,8.678,3.888,8.678,8.678C316.746,343.231,312.867,347.119,308.068,347.119"
          />
          <path
            style={{ fill: '#EDDE64' }}
            d="M308.068,303.729H203.932c-4.799,0-8.678-3.888-8.678-8.678c0-4.79,3.879-8.678,8.678-8.678
			h104.136c4.799,0,8.678,3.888,8.678,8.678C316.746,299.841,312.867,303.729,308.068,303.729"
          />
          <path
            style={{ fill: '#EDDE64' }}
            d="M308.068,260.339H203.932c-4.799,0-8.678-3.888-8.678-8.678s3.879-8.678,8.678-8.678h104.136
			c4.799,0,8.678,3.888,8.678,8.678S312.867,260.339,308.068,260.339"
          />
        </g>
        <g>
          <path
            style={{ fill: '#ECBA16' }}
            d="M256,52.068l17.104,34.712h34.964l-26.034,35.024l8.183,34.399L256,138.847l-34.217,17.356
			l8.183-34.399L203.932,86.78h34.964L256,52.068z"
          />
          <path style={{ fill: '#ECBA16' }} d="M108.475,512h295.051v-34.712H108.475V512z" />
          <path
            style={{ fill: '#ECBA16' }}
            d="M342.78,130.169L342.78,130.169V43.39h69.424v17.356
			C412.203,99.085,381.119,130.169,342.78,130.169"
          />
          <path
            style={{ fill: '#ECBA16' }}
            d="M169.22,130.169L169.22,130.169c-38.339,0-69.424-31.084-69.424-69.424V43.39h69.424V130.169z"
          />
        </g>
        <path
          style={{ fill: '#B58A0F' }}
          d="M299.39,433.898c0,23.96-19.43,43.39-43.39,43.39s-43.39-19.43-43.39-43.39
		c0-23.96,19.43-43.39,43.39-43.39S299.39,409.938,299.39,433.898"
        />
        <g>
          <path
            style={{ fill: '#EDDE64' }}
            d="M212.61,433.898c0-14.258,6.977-26.806,17.59-34.712h-60.902
			c-14.423,0-26.112,11.689-26.112,26.112v51.99H256C232.04,477.288,212.61,457.858,212.61,433.898"
          />
          <path
            style={{ fill: '#EDDE64' }}
            d="M342.703,399.186h-60.911c10.622,7.906,17.599,20.454,17.599,34.712
			c0,23.96-19.43,43.39-43.39,43.39h112.814v-51.99C368.815,410.876,357.126,399.186,342.703,399.186"
          />
        </g>
        <path
          style={{ fill: '#ECBA16' }}
          d="M368.814,17.356H143.186c-4.799,0-8.678-3.888-8.678-8.678S138.388,0,143.186,0h225.627
		c4.799,0,8.678,3.888,8.678,8.678S373.612,17.356,368.814,17.356"
        />
      </g>
    </svg>
  );
}

function SuperGrandChampion(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <g transform="translate(1 1)">
        <path
          style={{ fill: '#FFDD09' }}
          d="M408.6,502.467H101.4v-12.8c0-11.947,9.387-21.333,21.333-21.333h264.533
		c11.947,0,21.333,9.387,21.333,21.333V502.467z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M122.733,468.333h-25.6c-11.947,0-21.333,9.387-21.333,21.333v12.8h25.6v-12.8
		C101.4,477.72,110.787,468.333,122.733,468.333"
        />
        <path
          style={{ fill: '#FD9808' }}
          d="M412.867,468.333h-25.6c11.947,0,21.333,9.387,21.333,21.333v12.8h25.6v-12.8
		C434.2,477.72,424.813,468.333,412.867,468.333"
        />
        <path style={{ fill: '#FFDD09' }} d="M178.2,468.333h153.6v-68.267H178.2V468.333z" />
        <path style={{ fill: '#FFFFFF' }} d="M152.6,468.333h25.6v-68.267h-25.6V468.333z" />
        <path style={{ fill: '#FD9808' }} d="M331.8,468.333h25.6v-68.267h-25.6V468.333z" />
        <path
          style={{ fill: '#FFDD09' }}
          d="M265.24,251.587L255,237.933l-10.24,13.653v114.347l-6.827,34.133h34.133l-6.827-34.133V251.587z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M229.4,365.933l-17.067,34.133h25.6l6.827-34.133V251.587L255,237.933l-25.6,13.653V365.933z"
        />
        <path
          style={{ fill: '#FD9808' }}
          d="M280.6,365.933V251.587L255,237.933l10.24,13.653v114.347l6.827,34.133h25.6L280.6,365.933z"
        />
        <path
          style={{ fill: '#FFDD09' }}
          d="M255,7.533l42.667,93.867l102.4,12.8L331.8,186.733l17.067,102.4L255,237.933l-93.867,51.2
		l17.067-102.4l-68.267-76.8l102.4-8.533L255,7.533z"
        />
        <path
          style={{ fill: '#FD9808' }}
          d="M255,109.933l17.067,34.133L306.2,152.6l-25.6,25.6l8.533,34.133L255,195.267l-34.133,17.067
		L229.4,178.2l-25.6-25.6l34.133-8.533L255,109.933z"
        />
        <path
          d="M348.867,297.667c-1.707,0-2.56,0-4.267-0.853L255,247.32l-89.6,48.64c-2.56,1.707-5.973,1.707-9.387-0.853
		c-2.56-1.707-4.267-5.12-3.413-8.533l16.213-98.133l-65.707-73.387c-2.56-2.56-2.56-5.973-1.707-8.533
		c1.707-2.56,4.267-5.12,7.68-5.12l97.28-8.533l40.96-89.6C249.027,0.707,251.587-1,255-1c3.413,0,5.973,1.707,7.68,5.12l40.96,89.6
		l97.28,11.947c3.413,0,5.973,2.56,6.827,5.973c0.853,3.413,0,6.827-1.707,8.533l-65.707,69.973l17.067,97.28
		c0.853,3.413-0.853,6.827-3.413,8.533C352.28,296.813,350.573,297.667,348.867,297.667z M255,229.4c1.707,0,2.56,0,4.267,0.853
		l78.507,42.667l-14.507-85.333c-0.853-2.56,0-5.12,2.56-6.827L383,120.173l-85.333-10.24c-2.56,0-5.12-2.56-6.827-5.12
		l-34.987-76.8l-34.987,76.8c-0.853,2.56-4.267,5.12-6.827,5.12l-85.333,6.827l57.173,64c1.707,1.707,2.56,4.267,1.707,6.827
		L173.08,272.92l78.507-42.667C252.44,229.4,253.293,229.4,255,229.4z"
        />
        <path
          d="M434.2,511H75.8c-5.12,0-8.533-3.413-8.533-8.533v-12.8c0-16.213,13.653-29.867,29.867-29.867h315.733
		c16.213,0,29.867,13.653,29.867,29.867v12.8C442.733,507.587,439.32,511,434.2,511z M84.333,493.933h341.333v-4.267
		c0-6.827-5.973-12.8-12.8-12.8H97.133c-6.827,0-12.8,5.973-12.8,12.8V493.933z"
        />
        <path
          d="M357.4,476.867H152.6c-5.12,0-8.533-3.413-8.533-8.533v-68.267c0-5.12,3.413-8.533,8.533-8.533h204.8
		c5.12,0,8.533,3.413,8.533,8.533v68.267C365.933,473.453,362.52,476.867,357.4,476.867z M161.133,459.8h187.733v-51.2H161.133
		V459.8z"
        />
        <path
          d="M289.133,220.867c-1.707,0-2.56,0-3.413-0.853L255,204.653l-30.72,15.36c-2.56,1.707-6.827,0.853-9.387-0.853
		c-2.56-1.707-3.413-5.12-2.56-8.533l7.68-29.867l-22.187-22.187c-2.56-2.56-3.413-5.12-2.56-8.533
		c0.853-3.413,3.413-5.12,5.973-5.973l30.72-7.68l15.36-30.72c2.56-5.973,12.8-5.973,15.36,0l15.36,30.72l30.72,7.68
		c2.56,0.853,5.12,3.413,5.973,5.973s0,5.973-2.56,8.533l-22.187,22.187l7.68,29.867c0.853,3.413,0,6.827-2.56,8.533
		C292.547,220.013,290.84,220.867,289.133,220.867z M255,186.733c1.707,0,2.56,0,3.413,0.853l17.92,8.533l-4.267-16.213
		c-0.853-2.56,0-5.973,2.56-7.68l15.36-15.36L270.36,152.6c-2.56-0.853-4.267-2.56-5.973-4.267L255,128.707l-9.387,18.773
		c-0.853,2.56-3.413,4.267-5.973,4.267l-18.773,5.12l15.36,15.36c1.707,1.707,2.56,5.12,2.56,7.68l-4.267,16.213l17.92-8.533
		C252.44,186.733,253.293,186.733,255,186.733z"
        />
        <path
          d="M297.667,408.6h-85.333c-2.56,0-5.973-1.707-7.68-4.267s-1.707-5.973,0-8.533l16.213-32.427V251.587
		c0-3.413,1.707-5.973,4.267-7.68l25.6-13.653c2.56-1.707,5.973-1.707,8.533,0l25.6,13.653c2.56,1.707,4.267,4.267,4.267,7.68
		v111.787l16.213,32.427c1.707,2.56,0.853,5.973,0,8.533S300.227,408.6,297.667,408.6z M225.987,391.533h58.027l-11.093-22.187
		c-0.853-0.853-0.853-1.707-0.853-3.413V256.707L255,247.32l-17.067,9.387v109.227c0,1.707,0,2.56-0.853,3.413L225.987,391.533z"
        />
      </g>
    </svg>
  );
}

function VeteranChampion(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 64 64" {...props}>
      <g id="Flat">
        <g id="Color">
          <circle cx="32" cy="58" fill="#f9a215" r="1" />
          <path
            d="M47.2,30c0-1.73,1.29-3.64.78-5.19s-2.7-2.4-3.68-3.74-1.07-3.67-2.42-4.66-3.57-.35-5.18-.87-3-2.34-4.7-2.34S28.86,15,27.3,15.54s-3.83-.11-5.18.87-1.43,3.3-2.42,4.66S16.54,23.19,16,24.81s.78,3.46.78,5.19-1.29,3.64-.78,5.19,2.7,2.4,3.68,3.74,1.07,3.67,2.42,4.66,3.57.35,5.18.87,3,2.34,4.7,2.34,3.14-1.83,4.7-2.34,3.83.11,5.18-.87,1.43-3.3,2.42-4.66,3.16-2.12,3.68-3.74S47.2,31.73,47.2,30Z"
            fill="#fccd1d"
          />
          <circle cx="32" cy="30.09" fill="#f9a215" r="11.45" />
          <path
            d="M33.1,24l1.21,2.11a1.26,1.26,0,0,0,.85.6l2.43.48a1.22,1.22,0,0,1,.67,2L36.59,31a1.22,1.22,0,0,0-.33,1l.29,2.4a1.26,1.26,0,0,1-1.78,1.27l-2.24-1a1.3,1.3,0,0,0-1.06,0l-2.24,1a1.26,1.26,0,0,1-1.78-1.27l.29-2.4a1.22,1.22,0,0,0-.33-1l-1.67-1.78a1.22,1.22,0,0,1,.67-2l2.43-.48a1.26,1.26,0,0,0,.85-.6L30.9,24A1.28,1.28,0,0,1,33.1,24Z"
            fill="#fccd1d"
          />
          <path
            d="M11.5,54c-2.58,0-4.81-1.87-5.86-2.92a1,1,0,0,1,0-1.41c1.05-1.05,3.28-2.92,5.86-2.92s4.81,1.87,5.86,2.92a1,1,0,0,1,0,1.41C16.31,52.13,14.08,54,11.5,54Z"
            fill="#fccd1d"
          />
          <path
            d="M6.48,41.81a6.52,6.52,0,0,1-3.29-3.44,1,1,0,0,1,.48-1.32,6.61,6.61,0,0,1,4.73-.52A6.63,6.63,0,0,1,11.69,40a1,1,0,0,1-.48,1.32A6.62,6.62,0,0,1,6.48,41.81Z"
            fill="#fccd1d"
          />
          <path
            d="M4.88,29.93A4.61,4.61,0,0,1,3,27.41a1,1,0,0,1,.74-1.3,4.62,4.62,0,0,1,3.12.33A4.55,4.55,0,0,1,8.74,29,1,1,0,0,1,8,30.26,4.57,4.57,0,0,1,4.88,29.93Z"
            fill="#fccd1d"
          />
          <path
            d="M4.26,20.15a3.9,3.9,0,0,1-1.58-2.23,1,1,0,0,1,.73-1.26,4,4,0,0,1,2.72.25A3.94,3.94,0,0,1,7.7,19.14,1,1,0,0,1,7,20.4,3.87,3.87,0,0,1,4.26,20.15Z"
            fill="#fccd1d"
          />
          <path
            d="M52.5,54c2.58,0,4.81-1.87,5.86-2.92a1,1,0,0,0,0-1.41c-1-1.05-3.28-2.92-5.86-2.92s-4.81,1.87-5.86,2.92a1,1,0,0,0,0,1.41C47.69,52.13,49.92,54,52.5,54Z"
            fill="#fccd1d"
          />
          <path
            d="M57.52,41.81a6.52,6.52,0,0,0,3.29-3.44,1,1,0,0,0-.48-1.32,6.61,6.61,0,0,0-4.73-.52A6.63,6.63,0,0,0,52.31,40a1,1,0,0,0,.48,1.32A6.62,6.62,0,0,0,57.52,41.81Z"
            fill="#fccd1d"
          />
          <path
            d="M59.12,29.93A4.61,4.61,0,0,0,61,27.41a1,1,0,0,0-.74-1.3,4.62,4.62,0,0,0-3.12.33A4.55,4.55,0,0,0,55.26,29,1,1,0,0,0,56,30.26,4.57,4.57,0,0,0,59.12,29.93Z"
            fill="#fccd1d"
          />
          <path
            d="M59.74,20.15a3.9,3.9,0,0,0,1.58-2.23,1,1,0,0,0-.73-1.26,4,4,0,0,0-2.72.25,3.94,3.94,0,0,0-1.57,2.23A1,1,0,0,0,57,20.4,3.87,3.87,0,0,0,59.74,20.15Z"
            fill="#fccd1d"
          />
          <path
            d="M28.5,57.14C15,49.22,8.75,35.2,9,13V9.76a1,1,0,0,0-2,0V13C6.74,36,13.26,50.55,27.5,58.86A.9.9,0,0,0,28,59a1,1,0,0,0,.5-1.86Z"
            fill="#f9a215"
          />
          <path
            d="M56,8.76a1,1,0,0,0-1,1V13c.24,22.2-6,36.22-19.51,44.14A1,1,0,0,0,36,59a.9.9,0,0,0,.5-.14C50.74,50.55,57.26,36,57,13V9.76A1,1,0,0,0,56,8.76Z"
            fill="#f9a215"
          />
          <path
            d="M6.46,9.76a4.29,4.29,0,0,1,1-2.5.7.7,0,0,1,1.09,0,4.22,4.22,0,0,1,1,2.5,4.22,4.22,0,0,1-1,2.5.7.7,0,0,1-1.09,0A4.29,4.29,0,0,1,6.46,9.76Z"
            fill="#fccd1d"
          />
          <path
            d="M57.54,9.76a4.29,4.29,0,0,0-1-2.5.7.7,0,0,0-1.09,0,4.22,4.22,0,0,0-1,2.5,4.22,4.22,0,0,0,1,2.5.7.7,0,0,0,1.09,0A4.29,4.29,0,0,0,57.54,9.76Z"
            fill="#fccd1d"
          />
        </g>
      </g>
    </svg>
  );
}

function HonoraryManufacturer(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 120 120" {...props}>
      <g>
        <polygon
          className="fill-[#FFC54D]"
          points="33.6,98.5 60,86.8 86.4,98.5 86.4,25.8 33.6,25.8  "
        />
        <polygon
          className="fill-[#F46262]"
          points="60,82.4 37.6,92.3 37.6,29.9 82.4,29.9 82.4,92.3  "
        />
        <path
          className="fill-[#EDB248]"
          d="M60.7,42.6l3.8,7.6c0.1,0.2,0.3,0.4,0.6,0.4l8.4,1.2c0.6,0.1,0.8,0.8,0.4,1.3L67.7,59   c-0.2,0.2-0.3,0.4-0.2,0.7l1.4,8.4c0.1,0.6-0.5,1.1-1.1,0.8l-7.5-4c-0.2-0.1-0.5-0.1-0.7,0l-7.5,4c-0.5,0.3-1.2-0.2-1.1-0.8   l1.4-8.4c0-0.2,0-0.5-0.2-0.7l-6.1-5.9c-0.4-0.4-0.2-1.2,0.4-1.3l8.4-1.2c0.2,0,0.4-0.2,0.6-0.4l3.8-7.6C59.6,42,60.4,42,60.7,42.6   z"
        />
        <path
          className="fill-[#B78B6A]"
          d="M89.9,30.7H30.1c-2.5,0-4.6-2.1-4.6-4.6v0c0-2.5,2.1-4.6,4.6-4.6h59.8c2.5,0,4.6,2.1,4.6,4.6v0   C94.5,28.7,92.5,30.7,89.9,30.7z"
        />
      </g>
    </svg>
  );
}

function ColorChampion(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 120 120" {...props}>
      <g>
        <path
          className="fill-[#FFC54D]"
          d="M61.8,16.5l10.3,20.6c0.3,0.5,0.8,1.1,1.6,1.1l22.8,3.3c1.6,0.3,2.2,2.2,1.1,3.5L81,61   c-0.5,0.5-0.8,1.1-0.5,1.9l3.8,22.8c0.3,1.6-1.4,3-3,2.2L60.9,77c-0.5-0.3-1.4-0.3-1.9,0L38.7,87.9c-1.4,0.8-3.3-0.5-3-2.2   l3.8-22.8c0-0.5,0-1.4-0.5-1.9L22.4,45c-1.1-1.1-0.5-3.3,1.1-3.5l22.8-3.3c0.5,0,1.1-0.5,1.6-1.1l10.3-20.6   C59.1,14.9,60.9,14.9,61.8,16.5z"
        />
        <path
          className="fill-[#7C6850]"
          d="M91.1,83.8H28.9c-1.3,0-2.3,0.9-2.6,2.1l-3.6,15.5c-0.4,1.7,0.9,3.3,2.6,3.3h69.5c1.7,0,3-1.6,2.6-3.3   l-3.6-15.5C93.5,84.6,92.4,83.8,91.1,83.8z"
        />
        <path
          className="fill-[#FFFFFF]"
          d="M73.6,98.3H46.4c-0.6,0-1.2-0.5-1.2-1.2v-5.9c0-0.6,0.5-1.2,1.2-1.2h27.1c0.6,0,1.2,0.5,1.2,1.2v5.9   C74.7,97.8,74.2,98.3,73.6,98.3z"
        />
      </g>
    </svg>
  );
}

function HonoraryWinnerOfExhibitions(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 120 120" {...props}>
      <g>
        <path
          className="fill-[#F46262]"
          d="M79.6,46.2c-0.7,0-1.3,0.3-1.7,0.9c-4,5.6-10.5,9.3-17.9,9.3s-13.9-3.7-17.9-9.3c-0.4-0.5-1-0.9-1.7-0.9h-2.7   c-1.3,0-2.2,1.2-2,2.5l11,48.1c0.2,0.9,1,1.6,2,1.6h22.5c0.9,0,1.8-0.6,2-1.6l11-48.1c0.3-1.3-0.7-2.5-2-2.5H79.6z"
        />
        <circle className="fill-[#FFC54D]" cx="60" cy="34.5" r="19.7" />
        <g>
          <path
            className="fill-[#FFFFFF]"
            d="M67.8,73H52.2c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6h15.5c0.9,0,1.6-0.7,1.6-1.6    C69.4,73.7,68.7,73,67.8,73z"
          />
          <path
            className="fill-[#FFFFFF]"
            d="M52.2,68.8h15.5c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6H52.2c-0.9,0-1.6,0.7-1.6,1.6    C50.6,68.1,51.3,68.8,52.2,68.8z"
          />
          <path
            className="fill-[#FFFFFF]"
            d="M67.8,80.4H52.2c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6h15.5c0.9,0,1.6-0.7,1.6-1.6S68.7,80.4,67.8,80.4z"
          />
        </g>
        <path
          className="fill-[#DBB68F]"
          d="M37.4,105.2h45.2c0.9,0,1.5-0.7,1.5-1.5v-7.2c0-0.9-0.7-1.5-1.5-1.5H37.4c-0.9,0-1.5,0.7-1.5,1.5v7.2   C35.8,104.5,36.5,105.2,37.4,105.2z"
        />
        <path
          className="fill-[#FFFFFF]"
          d="M71.4,30.8c-0.6,0-1,0.4-1.1,0.9l-6.6-1l-3.1-6.2c0.3-0.2,0.6-0.6,0.6-1c0-0.6-0.5-1.1-1.1-1.1   c-0.6,0-1.1,0.5-1.1,1.1c0,0.5,0.3,0.9,0.7,1l-3,6.2l-6.8,1c-0.1-0.5-0.5-1-1.1-1c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1   c0.3,0,0.6-0.1,0.8-0.3l4.9,4.8l-1.2,6.8c-0.1,0-0.1,0-0.2,0c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1   c0-0.2-0.1-0.4-0.1-0.5l6.1-3.2l6.1,3.2c-0.1,0.2-0.1,0.3-0.1,0.5c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1   c0-0.6-0.5-1.1-1.1-1.1c-0.1,0-0.1,0-0.2,0l-1.2-6.8l4.9-4.7c0.2,0.2,0.4,0.3,0.7,0.3c0.6,0,1.1-0.5,1.1-1.1   C72.5,31.3,72,30.8,71.4,30.8z"
        />
      </g>
    </svg>
  );
}

function Premior(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 120 120" {...props}>
      <rect className="fill-[#E8B04B]" height="17.9" width="8.6" x="55.7" y="56.8" />
      <path
        className="fill-[#333333]"
        d="M82.1,104.3H37.9c-1,0-1.8-0.8-1.8-1.8V72.9c0-1,0.8-1.8,1.8-1.8h44.2c1,0,1.8,0.8,1.8,1.8v29.6  C83.9,103.5,83.1,104.3,82.1,104.3z"
      />
      <path
        className="fill-[#282828]"
        d="M31.4,109h57.2c1,0,1.8-0.8,1.8-1.8v-5.8c0-1-0.8-1.8-1.8-1.8H31.4c-1,0-1.8,0.8-1.8,1.8v5.8  C29.5,108.2,30.4,109,31.4,109z"
      />
      <path
        className="fill-[#FFC54D]"
        d="M61.6,12.5l7.8,15.8c0.3,0.5,0.8,0.9,1.3,1l17.4,2.5c1.5,0.2,2,2,1,3L76.5,47.1c-0.4,0.4-0.6,1-0.5,1.6L79,66  c0.2,1.5-1.3,2.6-2.6,1.9l-15.6-8.2c-0.5-0.3-1.1-0.3-1.7,0l-15.6,8.2c-1.3,0.7-2.8-0.4-2.6-1.9l3-17.3c0.1-0.6-0.1-1.2-0.5-1.6  L30.9,34.8c-1.1-1-0.5-2.8,1-3l17.4-2.5c0.6-0.1,1.1-0.4,1.3-1l7.8-15.8C59.1,11.2,60.9,11.2,61.6,12.5z"
      />
      <path
        className="fill-[#FFC54D]"
        d="M70.5,94.1h-21c-0.7,0-1.2-0.5-1.2-1.2V79.8c0-0.7,0.5-1.2,1.2-1.2h21c0.7,0,1.2,0.5,1.2,1.2v13.1  C71.7,93.6,71.1,94.1,70.5,94.1z"
      />
    </svg>
  );
}

function InterPremior(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 120 120" {...props}>
      <g>
        <path
          className="fill-[#FFC54D]"
          d="M83.8,60.8c6.9-1,12.4-3.7,16.3-8.2c7.8-9.1,6.4-21.8,6.4-22.4l-0.2-1.7H91.9c-0.1-3.4-0.4-6.8-1-10H29   c-0.5,3.2-0.8,6.6-1,10H13.7l-0.2,1.7c-0.1,0.5-1.4,13.3,6.4,22.4c3.9,4.5,9.4,7.2,16.3,8.2c5,8.8,11.5,15.5,16.9,18.9V88H36.6   c-2.4,0-4.3,1.9-4.3,4.3v9.2h55.6v-9.2c0-2.4-1.9-4.3-4.3-4.3H66.9v-8.2C72.3,76.3,78.8,69.6,83.8,60.8z M97.1,50.1   c-2.7,3.1-6.4,5.2-11,6.3c3.4-7.1,5.7-15.3,5.9-24h10.7C102.8,36,102.3,44.1,97.1,50.1z M22.9,50.1c-5.1-6-5.6-14.1-5.6-17.6H28   c0.2,8.7,2.5,16.9,5.9,24C29.3,55.3,25.6,53.2,22.9,50.1z"
        />
        <path
          className="fill-[#EDB248]"
          d="M60.5,34.6l2.9,5.8c0.1,0.2,0.2,0.3,0.5,0.3l6.4,0.9c0.5,0.1,0.6,0.6,0.3,1L65.9,47c-0.2,0.2-0.2,0.3-0.2,0.5   l1.1,6.4c0.1,0.5-0.4,0.8-0.8,0.6l-5.7-3c-0.2-0.1-0.4-0.1-0.5,0l-5.7,3c-0.4,0.2-0.9-0.2-0.8-0.6l1.1-6.4c0-0.2,0-0.4-0.2-0.5   l-4.6-4.5c-0.3-0.3-0.2-0.9,0.3-1l6.4-0.9c0.2,0,0.3-0.2,0.5-0.3l2.9-5.8C59.7,34.1,60.3,34.1,60.5,34.6z"
        />
        <g>
          <polygon
            className="fill-[#81E2A4]"
            points="19.1,83.9 23.4,78.6 27.7,83.9 27.7,28.5 19.1,28.5   "
          />
          <polygon
            className="fill-[#81E2A4]"
            points="92.3,28.5 92.3,83.9 96.6,78.6 100.9,83.9 100.9,28.5   "
          />
        </g>
      </g>
    </svg>
  );
}

function GrandInterChampion(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 120 120" {...props}>
      <g>
        <path
          className="fill-[#5497FF]"
          d="M48.1,91.5h23.9c0.6,0,1-0.4,1.1-1l12.1-75.4c0.2-1.1-1.2-1.8-2-1C80,17.9,73.5,23.8,63,28.2   c-5.1,2.1-18.2,5-22,5.8c-0.6,0.1-1,0.7-0.9,1.3L47,90.5C47,91.1,47.5,91.5,48.1,91.5z"
        />
        <path
          className="fill-[#DBB68F]"
          d="M86.8,94.1h-3.7V91c0-1-0.8-1.8-1.8-1.8H38.7c-1,0-1.8,0.8-1.8,1.8v3.1h-3.7c-1,0-1.8,0.8-1.8,1.8v8.5   c0,1,0.8,1.8,1.8,1.8h53.7c1,0,1.8-0.8,1.8-1.8V96C88.7,94.9,87.8,94.1,86.8,94.1z"
        />
        <path
          className="fill-[#FFC54D]"
          d="M61.6,42.5l2.5,5c0.1,0.2,0.2,0.3,0.4,0.3l5.5,0.8c0.5,0.1,0.7,0.6,0.3,1l-4,3.9c-0.1,0.1-0.2,0.3-0.2,0.5   l0.9,5.5c0.1,0.5-0.4,0.8-0.8,0.6l-5-2.6c-0.2-0.1-0.4-0.1-0.5,0l-5,2.6c-0.4,0.2-0.9-0.1-0.8-0.6L56,54c0-0.2,0-0.4-0.2-0.5   l-4-3.9c-0.3-0.3-0.2-0.9,0.3-1l5.5-0.8c0.2,0,0.3-0.1,0.4-0.3l2.5-5C60.8,42,61.4,42,61.6,42.5z"
        />
        <g>
          <path
            className="fill-[#FFFFFF]"
            d="M67.9,69.1H54.3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3h13.6c0.7,0,1.3-0.6,1.3-1.3S68.6,69.1,67.9,69.1z"
          />
          <path
            className="fill-[#FFFFFF]"
            d="M65.3,74.3h-8.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3h8.5c0.7,0,1.3-0.6,1.3-1.3    C66.7,74.9,66.1,74.3,65.3,74.3z"
          />
        </g>
      </g>
    </svg>
  );
}

function GrandInterPremior(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 120 120" {...props}>
      <g>
        <g>
          <path
            className="fill-[#FFC54D]"
            d="M74.5,101.6h-5.3v-2c0-0.8-0.6-1.4-1.4-1.4H65l-1-7.3h2.2c0.8,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4h-2.6    l-0.3-2h-6.9l-0.3,2h-2.6c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4h2.2l-1,7.3h-2.8c-0.8,0-1.4,0.6-1.4,1.4v2h-5.3    c-2,0-3.6,1.6-3.6,3.6v4.5H78v-4.5C78.1,103.2,76.5,101.6,74.5,101.6z"
          />
          <path
            className="fill-[#FFC54D]"
            d="M75.7,30.1v6h25V51c-1.4,2.6-8.5,14-29,11.5l-0.7,6c2.3,0.3,4.5,0.4,6.6,0.4c22,0,28.7-15.3,29-16l0.2-0.6    V30.1H75.7z"
          />
          <path
            className="fill-[#FFC54D]"
            d="M48.9,68.4l-0.7-6c-20.5,2.6-27.7-8.9-29-11.5V36.1h25v-6h-31v22.2l0.2,0.6c0.3,0.7,7,16,29,16    C44.4,68.8,46.6,68.7,48.9,68.4z"
          />
        </g>
        <g>
          <polygon
            className="fill-[#7A9FFF]"
            points="81.3,30.1 81.3,93.1 88.4,87.1 95.5,93.1 95.5,30.1   "
          />
          <polygon
            className="fill-[#7A9FFF]"
            points="24.4,93.1 31.5,87.1 38.6,93.1 38.6,30.1 24.4,30.1   "
          />
        </g>
        <path
          className="fill-[#FFC54D]"
          d="M60,89.3L60,89.3c-16.4,0-29.7-13.3-29.7-29.7v-40h59.3v40C89.6,76,76.3,89.3,60,89.3z"
        />
        <path
          className="fill-[#EDB248]"
          d="M60.6,36.5l3.8,7.6c0.1,0.2,0.3,0.4,0.6,0.4l8.4,1.2c0.6,0.1,0.8,0.8,0.4,1.3l-6.1,5.9   c-0.2,0.2-0.3,0.4-0.2,0.7l1.4,8.4c0.1,0.6-0.5,1.1-1.1,0.8l-7.5-4c-0.2-0.1-0.5-0.1-0.7,0l-7.5,4c-0.5,0.3-1.2-0.2-1.1-0.8   l1.4-8.4c0-0.2,0-0.5-0.2-0.7L46.1,47c-0.4-0.4-0.2-1.2,0.4-1.3l8.4-1.2c0.2,0,0.4-0.2,0.6-0.4l3.8-7.6   C59.6,35.9,60.3,35.9,60.6,36.5z"
        />
      </g>
    </svg>
  );
}

function SupremeChampion(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 120 120" {...props}>
      <g>
        <polygon
          className="fill-[#FF97B7]"
          points="75.7,58.3 60,66.2 44.3,58.3 24.9,97.1 37,95.9 43.2,106.3 60,72.9 76.8,106.3 83,95.9 95.1,97.1  "
        />
        <path
          className="fill-[#FFC54D]"
          d="M92.8,38.2l-1.1,4.2c-0.3,1.4-0.1,2.9,0.6,4.2l2.1,3.8c1.3,2.2,0.9,5.1-1.1,6.9l-3.2,2.9   c-1.1,1-1.7,2.4-1.8,3.8l-0.2,4.3c-0.2,2.6-2,4.8-4.6,5.2l-4.3,0.8c-1.4,0.3-2.7,1.1-3.6,2.2L73.1,80c-1.5,2.1-4.3,2.9-6.6,1.9   l-4-1.7c-1.4-0.6-2.8-0.6-4.2,0l-4.1,1.6c-2.4,0.9-5.2,0.1-6.7-2L45,76.1c-0.8-1.2-2.1-2-3.5-2.3L37.3,73c-2.6-0.5-4.4-2.7-4.5-5.3   l-0.2-4.4c0-1.5-0.6-2.8-1.7-3.8l-3.2-3c-1.9-1.8-2.3-4.6-0.9-6.9l2.3-3.8c0.8-1.2,1-2.7,0.6-4.1l-1-4.3c-0.6-2.5,0.6-5.1,2.9-6.3   l3.9-1.9c1.3-0.6,2.3-1.8,2.8-3.1l1.4-4.2c0.9-2.4,3.3-4,5.9-3.7l4.4,0.4c1.4,0.2,2.9-0.2,4.1-1.2l3.4-2.7c2-1.6,4.9-1.6,6.9,0.1   l3.4,2.7c1.2,0.9,2.6,1.4,4,1.2l4.3-0.4c2.6-0.3,5,1.3,5.8,3.8l1.3,4.1c0.4,1.4,1.5,2.6,2.8,3.2l3.9,2   C92.2,33.1,93.5,35.7,92.8,38.2z"
        />
        <circle className="fill-[#E8B04B]" cx="60" cy="48.6" r="25.4" />
      </g>
    </svg>
  );
}

function SupremePremior(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 120 120" {...props}>
      <g>
        <polygon
          className="fill-[#647EFF]"
          points="60,13.7 70.7,19.9 83.1,19.9 89.3,30.7 100.1,36.9 100.1,49.3 106.3,60 100.1,70.7 100.1,83.1    89.3,89.3 83.1,100.1 70.7,100.1 60,106.3 49.3,100.1 36.9,100.1 30.7,89.3 19.9,83.1 19.9,70.7 13.7,60 19.9,49.3 19.9,36.9    30.7,30.7 36.9,19.9 49.3,19.9  "
        />
        <g>
          <path
            className="fill-[#FFFFFF]"
            d="M60,93.9c-18.7,0-33.9-15.2-33.9-33.9S41.3,26.1,60,26.1S93.9,41.3,93.9,60S78.7,93.9,60,93.9z M60,29    c-17.1,0-31,13.9-31,31s13.9,31,31,31s31-13.9,31-31S77.1,29,60,29z"
          />
        </g>
        <g>
          <path
            className="fill-[#FFD77A]"
            d="M56.3,72.6L41.6,60.9c-1.2-1-1.4-2.7-0.4-3.9l0,0c1-1.2,2.7-1.4,3.9-0.4l12.6,10.1l16.8-18.8    c1-1.1,2.8-1.2,3.9-0.2v0c1.1,1,1.2,2.8,0.2,3.9L60.1,72.3C59.1,73.4,57.4,73.5,56.3,72.6z"
          />
        </g>
      </g>
    </svg>
  );
}

export {
  JuniorBreedChampion,
  JuniorCountryChampion,
  JuniorCountryGrandChampion,
  BreedChampion,
  CountryChampion,
  GrandCountryChampion,
  SuperGrandChampion,
  VeteranChampion,
  HonoraryManufacturer,
  ColorChampion,
  HonoraryWinnerOfExhibitions,
  Premior,
  InterPremior,
  GrandInterChampion,
  GrandInterPremior,
  SupremeChampion,
  SupremePremior
};

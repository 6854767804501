import React from 'react';

interface TooltipProps {
  children: React.ReactNode;
  text: string;
  alignment?: 'left' | 'right';
}

export default function Tooltip({ children, text, alignment = 'left' }: TooltipProps) {
  return (
    <div className="group relative" tabIndex={0} aria-describedby="tooltip-text">
      {children}
      <span
        id="tooltip-text"
        className={`absolute z-10 hidden whitespace-nowrap rounded-md bg-gray-600 px-2 py-1 text-sm text-white transition-opacity duration-300 dark:bg-slate-600 ${
          alignment === 'left' ? 'right-1/4 translate-x-1/4' : 'left-1/4 -translate-x-1/4'
        } bottom-full mb-2 group-hover:block group-focus:block`}
        role="tooltip"
      >
        {text}
        {/* Стрелка тултипа */}
        <span
          className={`absolute top-full h-0 w-0 transform border-4 border-transparent ${
            alignment === 'left'
              ? 'right-1/3 translate-x-1/3 border-t-gray-600 dark:border-t-slate-600'
              : 'left-1/3 -translate-x-1/3 border-t-gray-600 dark:border-t-slate-600'
          }`}
        ></span>
      </span>
    </div>
  );
}

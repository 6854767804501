function Hospital() {
  return (
    <svg
      className="mx-1.5 h-8 w-8 rounded-full border-2 border-yellow-500 fill-yellow-500 p-0.5"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20.56,7.571l-8-5.4a1,1,0,0,0-1.12,0l-8,5.4A1,1,0,0,0,3,8.4V21a1,1,0,0,0,1,1H20a1,1,0,0,0,1-1V8.4A1,1,0,0,0,20.56,7.571ZM19,20H5V8.932l7-4.726,7,4.726ZM13,10v2h2v2H13v2H11V14H9V12h2V10Z" />
    </svg>
  );
}

function Pharmacy() {
  return (
    <svg
      className="mx-1.5 h-8 w-8 rounded-full border-2 border-green-500 fill-green-500 p-1"
      viewBox="0 0 31.711 31.71"
    >
      <g>
        <path
          d="M31.076,12.891c0.642-0.413,0.828-1.268,0.415-1.911l-4.714-7.33c-0.198-0.309-0.511-0.526-0.869-0.604
          s-0.733-0.01-1.042,0.188l-3.29,2.117V1.45c0-0.764-0.619-1.382-1.383-1.382H11.52c-0.764,0-1.382,0.619-1.382,1.382v3.902
          L6.845,3.234c-0.642-0.413-1.497-0.227-1.91,0.415L0.219,10.98c-0.198,0.308-0.266,0.683-0.187,1.042
          c0.077,0.358,0.295,0.671,0.603,0.869l4.609,2.964l-4.608,2.962c-0.309,0.198-0.526,0.511-0.604,0.87
          c-0.078,0.357-0.011,0.732,0.187,1.042l4.716,7.331c0.198,0.309,0.511,0.525,0.869,0.604c0.358,0.078,0.733,0.01,1.041-0.188
          l3.293-2.117v3.9c0,0.765,0.618,1.384,1.382,1.384h8.673c0.764,0,1.383-0.619,1.383-1.384v-3.9l3.29,2.117
          c0.309,0.197,0.684,0.266,1.042,0.188s0.671-0.295,0.869-0.604l4.714-7.332c0.413-0.643,0.227-1.497-0.415-1.911l-4.608-2.962
          L31.076,12.891z M13.834,25.417c-0.483,0-0.941-0.246-1.207-0.66c-0.055-0.084-0.101-0.176-0.139-0.275
          c-0.275-0.743,0.104-1.568,0.846-1.846c0.394-0.145,0.756-0.287,1.093-0.424v-5.367c-1.855-1.088-3.468-2.197-3.827-3.817
          c-0.565-2.529,0.263-3.998,1.056-4.783c0.425-0.422,0.93-0.737,1.476-0.969c0.415-0.175,0.85-0.304,1.296-0.396V5.808
          c0-0.792,0.637-1.435,1.431-1.435c0.793,0,1.428,0.642,1.428,1.435v0.906c0.749,0.043,1.396,0.14,1.831,0.219
          c0.263,0.048,0.447,0.088,0.533,0.108c0.771,0.18,1.25,0.95,1.072,1.721c-0.148,0.636-0.694,1.074-1.312,1.111
          c-0.5-0.102-1.283-0.238-2.124-0.293c-1.006-0.068-2.093-0.019-2.858,0.343c0,0-0.601,0.318-0.793,0.508
          c-0.404,0.401-0.653,1.087-0.233,1.971c0.121,0.255,0.478,0.649,1.026,1.039v-2.968l2.858,0.574v9.773
          c1.149-0.712,1.233-1.104,1.235-1.109c-0.017-0.092-1.057-4.424-1.057-4.424c2.128,1.247,4.086,2.6,3.915,4.663
          c-0.114,1.402-1.19,2.612-3.479,3.825l-0.615,0.271l-2.858,1.258v-0.012c-0.03,0.012-0.062,0.023-0.093,0.035
          C14.167,25.388,14.001,25.417,13.834,25.417z M17.285,25.902c0,0.792-0.635,1.436-1.428,1.436c-0.794,0-1.431-0.643-1.431-1.436
          v-0.598l2.858-0.557L17.285,25.902L17.285,25.902z"
        />
      </g>
    </svg>
  );
}

function Shop() {
  return (
    <svg
      viewBox="0 0 24 24"
      className="mx-1.5 h-8 w-8 rounded-full border-2 border-blue-500 fill-blue-500 p-0.5"
    >
      <path
        d="M14.5 21V16C14.5 15.4477 14.0523 15 13.5 15H10.5C9.94772 15 9.5 15.4477 9.5 16V21"
        stroke="#222222"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 11V17C5 18.8856 5 19.8284 5.58579 20.4142C6.17157 21 7.11438 21 9 21H15C16.8856 21 17.8284 21 18.4142 20.4142C19 19.8284 19 18.8856 19 17V11"
        stroke="#222222"
      />
      <path
        d="M4.62127 4.51493C4.80316 3.78737 4.8941 3.42359 5.16536 3.21179C5.43663 3 5.8116 3 6.56155 3H17.4384C18.1884 3 18.5634 3 18.8346 3.21179C19.1059 3.42359 19.1968 3.78737 19.3787 4.51493L20.5823 9.32938C20.6792 9.71675 20.7276 9.91044 20.7169 10.0678C20.6892 10.4757 20.416 10.8257 20.0269 10.9515C19.8769 11 19.6726 11 19.2641 11V11C18.7309 11 18.4644 11 18.2405 10.9478C17.6133 10.8017 17.0948 10.3625 16.8475 9.76781C16.7593 9.55555 16.7164 9.29856 16.6308 8.78457V8.78457C16.6068 8.64076 16.5948 8.56886 16.5812 8.54994C16.5413 8.49439 16.4587 8.49439 16.4188 8.54994C16.4052 8.56886 16.3932 8.64076 16.3692 8.78457L16.2877 9.27381C16.2791 9.32568 16.2747 9.35161 16.2704 9.37433C16.0939 10.3005 15.2946 10.9777 14.352 10.9995C14.3289 11 14.3026 11 14.25 11V11C14.1974 11 14.1711 11 14.148 10.9995C13.2054 10.9777 12.4061 10.3005 12.2296 9.37433C12.2253 9.35161 12.2209 9.32568 12.2123 9.27381L12.1308 8.78457C12.1068 8.64076 12.0948 8.56886 12.0812 8.54994C12.0413 8.49439 11.9587 8.49439 11.9188 8.54994C11.9052 8.56886 11.8932 8.64076 11.8692 8.78457L11.7877 9.27381C11.7791 9.32568 11.7747 9.35161 11.7704 9.37433C11.5939 10.3005 10.7946 10.9777 9.85199 10.9995C9.82887 11 9.80258 11 9.75 11V11C9.69742 11 9.67113 11 9.64801 10.9995C8.70541 10.9777 7.90606 10.3005 7.7296 9.37433C7.72527 9.35161 7.72095 9.32568 7.7123 9.27381L7.63076 8.78457C7.60679 8.64076 7.59481 8.56886 7.58122 8.54994C7.54132 8.49439 7.45868 8.49439 7.41878 8.54994C7.40519 8.56886 7.39321 8.64076 7.36924 8.78457V8.78457C7.28357 9.29856 7.24074 9.55555 7.15249 9.76781C6.90524 10.3625 6.38675 10.8017 5.75951 10.9478C5.53563 11 5.26905 11 4.73591 11V11C4.32737 11 4.12309 11 3.97306 10.9515C3.58403 10.8257 3.31078 10.4757 3.28307 10.0678C3.27239 9.91044 3.32081 9.71675 3.41765 9.32938L4.62127 4.51493Z"
        stroke="#222222"
      />
    </svg>
  );
}

function Laboratory() {
  return (
    <svg
      className="mx-1.5 h-8 w-8 rounded-full border-2 border-sky-500 fill-sky-500 p-0.5"
      viewBox="0 0 24 24"
    >
      <path d="M10 17C10.5523 17 11 16.5523 11 16C11 15.4477 10.5523 15 10 15C9.44772 15 9 15.4477 9 16C9 16.5523 9.44772 17 10 17Z" />
      <path d="M15 18C15 18.5523 14.5523 19 14 19C13.4477 19 13 18.5523 13 18C13 17.4477 13.4477 17 14 17C14.5523 17 15 17.4477 15 18Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 3V7.58152C17.9318 8.76829 20 11.6426 20 15C20 19.4183 16.4183 23 12 23C7.58172 23 4 19.4183 4 15C4 11.6426 6.06817 8.76829 9 7.58152V3H8C7.44772 3 7 2.55228 7 2C7 1.44772 7.44772 1 8 1H16C16.5523 1 17 1.44772 17 2C17 2.55228 16.5523 3 16 3H15ZM9.75043 9.43539L10.3752 9.18249C10.7529 9.02962 11 8.66295 11 8.25555V3H13V8.25555C13 8.66295 13.2471 9.02962 13.6248 9.18249L14.2496 9.43539C15.1075 9.78268 15.8661 10.3221 16.4726 11L7.52739 11C8.13388 10.3221 8.89249 9.78268 9.75043 9.43539ZM6.3414 13C6.12025 13.6258 6 14.2991 6 15C6 18.3137 8.68629 21 12 21C15.3137 21 18 18.3137 18 15C18 14.2991 17.8798 13.6258 17.6586 13L6.3414 13Z"
      />
    </svg>
  );
}

function Ritual() {
  return (
    <svg
      className="mx-1.5 h-8 w-8 rounded-full border-2 border-slate-800 fill-slate-800 p-0.5 dark:border-white dark:fill-white"
      viewBox="0 0 297 297"
    >
      <g>
        <path d="m292.524,153.901c-1.537-2.393-4.034-4.003-6.848-4.415-2.817-0.418-5.669,0.411-7.828,2.261-0.11,0.095-11.396,9.252-18.213,3.762-6.038-4.863-9.862-10.756-11.367-17.516-4.216-18.927-20.038-31.294-37.634-29.37-14.796,1.604-26.391,12.458-29.673,26.181-0.184-0.002-0.366-0.015-0.551-0.015-13.282,0-25.172,6.326-33.043,16.243-7.529-9.484-18.738-15.669-31.321-16.192-3.27-13.74-14.872-24.611-29.681-26.217-17.599-1.916-33.418,10.442-37.636,29.373-1.503,6.757-5.326,12.648-11.366,17.514-6.913,5.568-18.141-3.703-18.212-3.763-2.16-1.849-5.009-2.673-7.829-2.261-2.813,0.412-5.311,2.023-6.848,4.415-9.619,14.966-3.324,37 14.967,52.392 10.043,8.45 23.185,14.137 36.779,14.137 9.132,0 18.467-2.572 27.196-8.595 18.715,27.472 53.309,50.878 58.534,54.321 1.643,1.083 3.529,1.625 5.416,1.625 1.887,0 3.774-0.542 5.417-1.625 5.293-3.491 40.746-27.483 59.269-55.426 9.164,6.808 19.057,9.699 28.727,9.699 13.591,0 26.738-5.688 36.779-14.138 18.291-15.39 24.585-37.424 14.966-52.39zm-260.412,37.334c-5.918-4.98-10.043-10.98-11.701-16.383 8.941,3.228 19.733,3.688 29.298-4.018 9.466-7.623 15.77-17.502 18.23-28.564 1.66-7.455 7.48-15.03 16.305-14.084 6.084,0.66 10.894,4.993 12.485,10.556-14.892,7.017-25.285,22.639-25.285,40.755 0,4.868 0.985,9.796 2.722,14.7-14.564,11.565-31.109,6.249-42.054-2.962zm115.255,54.785c-23.737-16.787-56.244-46.593-56.244-66.523 0-13.801 10.407-25.029 23.199-25.029 12.796,0 23.207,11.229 23.207,25.029 0,5.434 4.405,9.84 9.839,9.84 5.434,0 9.839-4.405 9.839-9.84 0-13.801 10.409-25.029 23.204-25.029 12.787,0 23.191,11.229 23.191,25.029-0.001,19.928-32.502,49.734-56.235,66.523zm117.521-54.785c-11.394,9.588-28.855,14.957-43.827,1.464 1.42-4.41 2.219-8.83 2.219-13.202 0-17.285-9.462-32.3-23.261-39.736 1.269-6.047 6.287-10.875 12.738-11.574 8.831-0.95 14.644,6.63 16.303,14.082 2.462,11.065 8.767,20.943 18.231,28.564 9.564,7.706 20.354,7.247 29.299,4.02-1.659,5.401-5.783,11.402-11.702,16.382z" />
        <path d="m95.468,86.2c16.046,4.814 37.232,7.465 59.66,7.465 22.428,0 43.614-2.65 59.66-7.465 19.499-5.849 29.385-14.179 29.385-24.759 0-10.578-9.886-18.909-29.385-24.758-16.046-4.814-37.232-7.465-59.66-7.465-22.428,0-43.614,2.65-59.66,7.465-19.499,5.849-29.385,14.18-29.385,24.758 1.42109e-14,10.58 9.886,18.91 29.385,24.759zm6.238-34.936c14.426-4.064 33.397-6.303 53.422-6.303s38.997,2.238 53.422,6.303c15.301,4.311 19.495,9.015 19.868,10.178-0.373,1.164-4.567,5.868-19.868,10.179-14.425,4.064-33.397,6.303-53.422,6.303s-38.996-2.238-53.422-6.303c-15.301-4.311-19.495-9.015-19.868-10.179 0.373-1.164 4.567-5.868 19.868-10.178z" />
      </g>
    </svg>
  );
}

function Barber() {
  return (
    <svg
      viewBox="0 0 512 512"
      className="mx-1.5 h-8 w-8 rounded-full border-2 border-teal-500 fill-teal-500 p-0.5"
    >
      <g>
        <g>
          <path
            d="M479.68,137.05l28.217-28.219l-24.185-24.185l-28.217,28.219l-16.124-16.124l28.218-28.218l-24.185-24.184l-28.218,28.218
              l-16.125-16.125l28.217-28.216L403.094,4.031l-28.217,28.216L342.63,0L225.734,116.895c-8.62,8.619-13.784,19.767-14.823,31.769
              C184.664,178.358,83.158,293.881,54.298,337.169c-12.737,19.106-19.906,39.474-20.734,58.902
              c-0.723,16.991,3.442,32.665,12.122,46.133L0.074,487.815L24.259,512l45.651-45.65c12.423,7.956,26.71,12.092,42.176,12.092
              c1.248,0,2.505-0.026,3.767-0.08c19.426-0.828,39.793-7.996,58.901-20.733c43.288-28.857,158.807-130.361,188.503-156.613
              c12.004-1.038,23.154-6.201,31.773-14.822l116.895-116.898L479.68,137.05z M155.783,429.171
              c-10.881,7.252-48.457,28.844-75.164,2.138c-26.707-26.71-5.116-64.285,2.138-75.166
              c23.77-35.653,103.206-127.224,140.344-169.551c0.841,0.977,1.711,1.937,2.633,2.858l96.743,96.746
              c0.921,0.921,1.879,1.789,2.856,2.631C283.008,325.965,191.441,405.398,155.783,429.171z M370.848,262.011
              c-3.231,3.229-7.526,5.006-12.094,5.006c-4.568,0-8.863-1.78-12.093-5.009l-96.742-96.746c-3.23-3.229-5.009-7.524-5.009-12.09
              s1.78-8.861,5.01-12.091l92.711-92.711l8.061,8.061l-28.214,28.214l24.185,24.185l28.214-28.214l16.126,16.126l-28.217,28.216
              l24.185,24.185l28.217-28.216l16.124,16.124l-28.214,28.217l24.185,24.185l28.214-28.217l8.061,8.061L370.848,262.011z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M346.656,165.263c-4.306-4.304-10.03-6.674-16.119-6.674c-6.087,0-11.81,2.369-16.128,6.681
              c-8.887,8.892-8.886,23.356,0.003,32.245c4.305,4.306,10.031,6.677,16.121,6.677c6.083,0,11.804-2.366,16.129-6.68
              c4.31-4.311,6.68-10.044,6.675-16.141C353.334,175.277,350.956,169.554,346.656,165.263z"
          />
        </g>
      </g>
    </svg>
  );
}

function Hotel() {
  return (
    <svg
      className="mx-1.5 h-8 w-8 rounded-full border-2 border-orange-500 fill-orange-500 p-0.5"
      viewBox="0 0 512 512"
    >
      <g>
        <g>
          <g>
            <path
              d="M200.789,69.591l13.845,10.048l-5.291,16.256c-2.859,8.811,0.277,18.432,7.744,23.851c7.488,5.44,17.621,5.44,25.088,0
                  L256,109.698l13.824,10.048c3.733,2.731,8.128,4.075,12.544,4.075c4.395,0,8.811-1.344,12.544-4.075
                  c7.467-5.419,10.603-15.04,7.744-23.851l-5.269-16.256l13.824-10.048c7.488-5.44,10.603-15.061,7.744-23.851
                  c-2.837-8.789-11.051-14.741-20.288-14.741h-17.088l-5.291-16.256C273.451,5.954,265.237,0.002,256,0.002
                  s-17.429,5.952-20.288,14.741l-5.291,16.256h-17.088c-9.237,0-17.429,5.952-20.288,14.741
                  C190.187,54.53,193.323,64.151,200.789,69.591z"
            />
            <path
              d="M371.45,69.591l13.845,10.048l-5.291,16.256c-2.859,8.811,0.277,18.432,7.744,23.851c7.488,5.44,17.621,5.44,25.088,0
                  l13.824-10.048l13.824,10.048c3.733,2.731,8.128,4.075,12.544,4.075c4.395,0,8.811-1.344,12.544-4.075
                  c7.467-5.419,10.603-15.04,7.744-23.851l-5.269-16.256l13.824-10.048c7.488-5.44,10.603-15.061,7.744-23.851
                  c-2.837-8.789-11.051-14.741-20.288-14.741h-17.088l-5.291-16.256c-2.837-8.789-11.051-14.741-20.288-14.741
                  s-17.429,5.952-20.288,14.741l-5.291,16.256h-17.088c-9.237,0-17.429,5.952-20.288,14.741
                  C360.847,54.53,363.983,64.151,371.45,69.591z"
            />
            <path
              d="M30.123,69.591l13.845,10.048l-5.291,16.256c-2.859,8.811,0.277,18.432,7.744,23.851c7.488,5.44,17.621,5.44,25.088,0
                  l13.824-10.048l13.824,10.048c3.733,2.731,8.128,4.075,12.544,4.075c4.395,0,8.811-1.344,12.544-4.075
                  c7.467-5.419,10.603-15.04,7.744-23.851l-5.269-16.256l13.824-10.048c7.488-5.44,10.603-15.061,7.744-23.851
                  c-2.837-8.789-11.051-14.741-20.288-14.741h-17.088l-5.291-16.256C102.784,5.954,94.571,0.002,85.333,0.002
                  c-9.237,0-17.429,5.952-20.288,14.741l-5.291,16.256H42.667c-9.237,0-17.429,5.952-20.288,14.741
                  C19.52,54.53,22.656,64.151,30.123,69.591z"
            />
            <path
              d="M490.667,277.331h-128V170.665c0-11.782-9.551-21.333-21.333-21.333H170.667c-11.782,0-21.333,9.551-21.333,21.333
                  v106.667h-128C9.551,277.331,0,286.882,0,298.665v192c0,11.782,9.551,21.333,21.333,21.333h149.333h170.667h149.333
                  c11.782,0,21.333-9.551,21.333-21.333v-192C512,286.882,502.449,277.331,490.667,277.331z M42.667,319.998h106.667v149.333
                  H42.667V319.998z M192,298.665V191.998h128v106.667v170.667h-42.667v-64c0-11.782-9.551-21.333-21.333-21.333
                  s-21.333,9.551-21.333,21.333v64H192V298.665z M469.333,469.331H362.667V319.998h106.667V469.331z"
            />
            <path
              d="M234.667,255.998h42.667c11.782,0,21.333-9.551,21.333-21.333c0-11.782-9.551-21.333-21.333-21.333h-42.667
                  c-11.782,0-21.333,9.551-21.333,21.333C213.333,246.447,222.885,255.998,234.667,255.998z"
            />
            <path
              d="M234.667,319.998h42.667c11.782,0,21.333-9.551,21.333-21.333c0-11.782-9.551-21.333-21.333-21.333h-42.667
                  c-11.782,0-21.333,9.551-21.333,21.333C213.333,310.447,222.885,319.998,234.667,319.998z"
            />
            <path
              d="M106.667,341.331H85.333c-11.782,0-21.333,9.551-21.333,21.333s9.551,21.333,21.333,21.333h21.333
                  c11.782,0,21.333-9.551,21.333-21.333S118.449,341.331,106.667,341.331z"
            />
            <path
              d="M106.667,405.331H85.333c-11.782,0-21.333,9.551-21.333,21.333s9.551,21.333,21.333,21.333h21.333
                  c11.782,0,21.333-9.551,21.333-21.333S118.449,405.331,106.667,405.331z"
            />
            <path
              d="M405.333,383.998h21.333c11.782,0,21.333-9.551,21.333-21.333s-9.551-21.333-21.333-21.333h-21.333
                  c-11.782,0-21.333,9.551-21.333,21.333S393.551,383.998,405.333,383.998z"
            />
            <path
              d="M405.333,447.998h21.333c11.782,0,21.333-9.551,21.333-21.333s-9.551-21.333-21.333-21.333h-21.333
                  c-11.782,0-21.333,9.551-21.333,21.333S393.551,447.998,405.333,447.998z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

function Equipment() {
  return (
    <svg
      className="mx-1.5 h-8 w-8 rounded-full border-2 border-amber-500 fill-amber-500 p-0.5"
      viewBox="-3.5 0 19 19"
    >
      <path d="M11.405 5.34a.554.554 0 1 1-.996.485 5.563 5.563 0 0 0-1.375-1.782 1.201 1.201 0 0 0-1.786 1.05v.61a.476.476 0 0 1-.475.475h-2.48a.476.476 0 0 1-.475-.475v.07a1.194 1.194 0 0 0-.196-.657 2.145 2.145 0 0 1-.084-.113 1.202 1.202 0 0 0-1.94.134.554.554 0 0 1-1.06-.23v-2.28a.554.554 0 0 1 1.07-.202 1.2 1.2 0 0 0 1.902.148 2.176 2.176 0 0 1 .137-.186l.007-.011h.003a2.167 2.167 0 0 1 1.668-.781h.03a.516.516 0 0 1 .058-.003 6.662 6.662 0 0 1 5.993 3.748zM6.768 7.455a.476.476 0 0 0-.475-.475H4.578a.477.477 0 0 0-.475.475V16.9a.476.476 0 0 0 .475.475h1.715a.476.476 0 0 0 .475-.475z" />
    </svg>
  );
}

function Daily() {
  return (
    <svg className="h-12 w-12 fill-yellow-600 dark:fill-yellow-300" viewBox="0 0 456.721 456.721">
      <g>
        <g id="Layer_8_65_">
          <path
            d="M161.698,221.225c0-6.041,1.929-9.933,5.778-11.688c3.85-1.753,9.233-2.629,16.153-2.629h87.461
              c8.405,0,14.492,1.313,18.266,3.94c3.767,2.626,5.649,6.96,5.649,12.999c0,3.68-2.224,8.533-6.645,14.58
              c-4.422,6.046-9.456,12.728-15.092,20.028c-5.653,7.304-11.48,16.394-17.467,27.239c-5.998,10.861-11.491,24.131-16.481,39.805
              c-2.102,6.903-3.593,12.649-4.464,17.202c-0.877,4.547-1.84,10.453-2.894,17.719c-1.054,7.278-1.979,12.826-2.763,16.688
              c-1.835,8.941-4.442,15.127-7.816,18.573c-3.374,3.458-7.855,5.197-13.458,5.197c-5.342,0-9.631-1.859-12.871-5.581
              c-3.24-3.721-4.858-9.226-4.858-16.471c0-5.604,1.182-13.22,3.548-22.852c2.363-9.63,6.212-21.165,11.559-34.604
              c5.344-13.445,12.021-27.229,20.026-41.379c8.013-14.141,17.531-28.204,28.568-42.212h-70.263c-7.357,0-12.848-1.335-16.478-4.017
              C163.524,231.094,161.698,226.913,161.698,221.225z M426.18,66.219v100.097c0,0.298-0.122,0.566-0.145,0.854v260.102
              c0,16.223-13.209,29.449-29.448,29.449H59.993c-16.239,0-29.451-13.204-29.451-29.449V118.724c0-0.46,0.11-0.887,0.128-1.335
              V66.225c0-6.235,8.048-11.302,17.981-11.302h32.102V33.845C80.752,15.174,95.932,0,114.591,0
              c18.658,0,33.837,15.174,33.837,33.845v21.078h53.002V33.845C201.43,15.174,216.609,0,235.273,0
              c18.656,0,33.836,15.174,33.836,33.845v21.078h48.298V33.845C317.407,15.174,332.586,0,351.244,0
              c18.659,0,33.838,15.174,33.838,33.845v21.078h23.116C418.111,54.922,426.18,59.984,426.18,66.219z M334.226,54.922h34.009V33.845
              c0-9.382-7.623-17.013-16.999-17.013c-9.387,0-17.01,7.631-17.01,17.013V54.922z M218.253,54.922h34.002V33.845
              c0-9.382-7.616-17.013-17.004-17.013c-9.373,0-16.998,7.631-16.998,17.013V54.922z M97.562,54.922h34.022V33.845
              c0-9.382-7.625-17.013-17.007-17.013c-9.378,0-17.015,7.631-17.015,17.013V54.922z M400.778,177.629H55.767v249.646
              c0,2.322,1.894,4.203,4.214,4.203h336.583c2.32,0,4.215-1.881,4.215-4.203V177.629z"
          />
        </g>
      </g>
    </svg>
  );
}

function Aquarium() {
  return (
    <svg
      className="mx-1.5 h-8 w-8 rounded-full border-2 border-cyan-500 fill-cyan-500 p-0.5"
      viewBox="0 0 512 512"
    >
      <path
        d="M386.412,35.549c-2.635-1.563-5.643-2.387-8.706-2.387H134.293c-3.063,0-6.071,0.824-8.706,2.387
      c-167.407,99.229-167.485,341.617-0.019,440.902c2.636,1.563,5.644,2.387,8.708,2.387h243.447c3.064,0,6.072-0.825,8.708-2.387
      C554.064,377.067,553.654,134.683,386.412,35.549z M139.067,67.312H372.93c27.035,16.795,50.246,39.292,67.884,65.728
      c-29.035-4.948-59.669,2.047-84.163,21.008c-0.113,0.088-0.224,0.176-0.335,0.265c-26.532,20.298-63.424,20.211-89.866-0.261
      c-41.056-31.789-97.304-28.409-132.346,0.262c-22.995,17.593-53.782,19.859-78.797,6.819
      C73.73,122.174,103.074,89.67,139.067,67.312z M372.948,444.688H139.05c-85.313-53.007-124.108-155.81-95.954-251.491
      c40.424,19.208,85.402,9.86,112.586-12.407c26.531-20.3,63.421-20.211,89.863,0.262c38.858,30.087,93.143,30.094,132.011,0.006
      c0.113-0.088,0.225-0.176,0.336-0.266c25.274-19.338,59.976-20.156,86.065-2.474C500.71,276.858,463.521,388.414,372.948,444.688z"
      />
      <path
        d="M199.598,288.976l-41.911-30.444c-7.63-5.542-18.308-3.851-23.85,3.779c-5.542,7.63-3.851,18.308,3.779,23.85l36.615,26.598
      l-36.615,26.598c-7.63,5.542-9.322,16.22-3.779,23.85c5.545,7.634,16.224,9.318,23.85,3.779l41.909-30.444
      c47.18,51.109,147.748,42.518,179.706-15.549c2.822-5.126,2.822-11.34,0-16.466C347.297,246.371,246.707,237.944,199.598,288.976z
       M224.107,312.759c27.586-31.008,91.757-31.086,119.415,0C315.937,343.765,251.765,343.846,224.107,312.759z"
      />
    </svg>
  );
}

function Expertise() {
  return (
    <svg
      className="mx-1.5 h-8 w-8 rounded-full border-2 border-cyan-500 fill-cyan-500 p-0.5"
      viewBox="0 0 24 24"
    >
      <g fillRule="evenodd">
        <circle cx="6" cy="16" r="1" />
        <path d="M12 2l10 17H2L12 2zm0-2a2 2 0 0 0-1.724.986l-10 17A2 2 0 0 0 2 21h20a2 2 0 0 0 1.724-3.014l-10-17A2 2 0 0 0 12 0z" />
        <circle cx="18" cy="16" r="1" />
        <circle cx="14" cy="16" r="1" />
        <circle cx="10" cy="16" r="1" />
        <circle cx="8" cy="13" r="1" />
        <circle cx="16" cy="13" r="1" />
        <circle cx="12" cy="13" r="1" />
        <circle cx="10" cy="10" r="1" />
        <circle cx="12" cy="7" r="1" />
        <circle cx="14" cy="10" r="1" />
      </g>
    </svg>
  );
}

function Shelter() {
  return (
    <svg
      className="mx-1.5 h-8 w-8 rounded-full border-2 border-emerald-500 fill-emerald-500 p-0.5"
      viewBox="0 0 297 297"
    >
      <g>
        <path
          d="M291.888,93.108L153.492,14.476c-3.096-1.759-6.887-1.759-9.982,0L5.113,93.108c-3.986,2.266-5.948,6.932-4.776,11.364
          c1.171,4.434,5.182,7.522,9.767,7.522h24.498v161.748c0,5.578,4.522,10.102,10.102,10.102h207.596
          c5.578,0,10.102-4.523,10.102-10.102V111.994h24.498c4.585,0,8.595-3.088,9.766-7.522
          C297.836,100.04,295.874,95.374,291.888,93.108z M242.197,101.892V263.64H54.804V101.892c0-4.574-3.04-8.438-7.211-9.683
          L148.5,34.876l100.907,57.333C245.237,93.453,242.197,97.317,242.197,101.892z"
        />
        <path
          d="M112.568,102.842c-25.39,0-46.045,20.761-46.045,46.278c0,18.996,13.29,39.916,39.502,62.18
          c18.44,15.662,36.628,26.318,37.394,26.765c1.572,0.916,3.33,1.374,5.087,1.374c1.758,0,3.515-0.458,5.088-1.374
          c0.765-0.446,18.951-11.104,37.388-26.766c26.208-22.264,39.496-43.184,39.496-62.179c0-25.518-20.654-46.278-46.042-46.278
          c-14.518,0-27.487,6.792-35.931,17.382C140.06,109.634,127.087,102.842,112.568,102.842z M148.505,159.223
          c5.578,0,10.102-4.523,10.102-10.103c0-14.377,11.587-26.074,25.828-26.074c14.248,0,25.839,11.697,25.839,26.074
          c0,22.138-35.97,52.274-61.769,68.41c-25.804-16.136-61.779-46.272-61.779-68.41c0-14.377,11.593-26.074,25.842-26.074
          c14.246,0,25.836,11.697,25.836,26.074C138.404,154.699,142.926,159.223,148.505,159.223z"
        />
      </g>
    </svg>
  );
}

export {
  Hospital,
  Pharmacy,
  Shop,
  Laboratory,
  Ritual,
  Barber,
  Hotel,
  Equipment,
  Aquarium,
  Expertise,
  Shelter,
  Daily
};

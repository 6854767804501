import React from 'react';

type ButtonType = Pick<JSX.IntrinsicElements, 'a' | 'button'>;

type Props<T extends keyof ButtonType> = {
  type: T;
  bg: 'blue' | 'yellow';
  children?: React.ReactNode;
} & JSX.IntrinsicElements[T];

export default function SquareButton<T extends keyof ButtonType>({
  type,
  children,
  bg,
  ...rest
}: Props<T>) {
  const colors = {
    blue: 'bg-blue-main text-white dark:bg-gray-600 dark:focus:bg-gray-700',
    yellow: ''
  };

  const colorClasses = colors[bg];

  return React.createElement(
    type,
    {
      ...rest,
      className: `mr-2 rounded-xl ${colorClasses} px-4 py-2 text-sm font-medium focus:outline-none focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-800 lg:px-5 lg:py-2.5`
    },
    children
  );
}

import { SVGProps } from 'react';

const ArrowRight = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="18"
      height="14"
      {...props}
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.364 7.707C17.5514 7.51947 17.6567 7.26516 17.6567 7C17.6567 6.73484 17.5514 6.48053 17.364 6.293L11.707 0.636001C11.5183 0.453842 11.2657 0.353048 11.0035 0.355327C10.7414 0.357605 10.4905 0.462774 10.3051 0.648183C10.1197 0.833592 10.0146 1.0844 10.0123 1.3466C10.01 1.6088 10.1108 1.8614 10.293 2.05L14.243 6L0.999952 6C0.734735 6 0.48038 6.10536 0.292844 6.2929C0.105308 6.48043 -4.82878e-05 6.73479 -4.82646e-05 7C-4.82414e-05 7.26522 0.105308 7.51957 0.292844 7.70711C0.480381 7.89464 0.734735 8 0.999952 8L14.243 8L10.293 11.95C10.1108 12.1386 10.01 12.3912 10.0123 12.6534C10.0146 12.9156 10.1197 13.1664 10.3051 13.3518C10.4905 13.5372 10.7414 13.6424 11.0036 13.6447C11.2657 13.647 11.5183 13.5462 11.707 13.364L17.364 7.707Z" />
    </svg>
  );
};

const Email = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" {...props} viewBox="0 0 24 24" fill="none">
      <path
        d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z"
        fill="currentColor"
      />
    </svg>
  );
};

const Location = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 11.5C11.337 11.5 10.7011 11.2366 10.2322 10.7678C9.76339 10.2989 9.5 9.66304 9.5 9C9.5 8.33696 9.76339 7.70107 10.2322 7.23223C10.7011 6.76339 11.337 6.5 12 6.5C12.663 6.5 13.2989 6.76339 13.7678 7.23223C14.2366 7.70107 14.5 8.33696 14.5 9C14.5 9.3283 14.4353 9.65339 14.3097 9.95671C14.1841 10.26 13.9999 10.5356 13.7678 10.7678C13.5356 10.9999 13.26 11.1841 12.9567 11.3097C12.6534 11.4353 12.3283 11.5 12 11.5ZM12 2C10.1435 2 8.36301 2.7375 7.05025 4.05025C5.7375 5.36301 5 7.14348 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 7.14348 18.2625 5.36301 16.9497 4.05025C15.637 2.7375 13.8565 2 12 2Z"
        fill="currentColor"
      />
    </svg>
  );
};

const InfiniteLoadingDotsIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="8em" height="3em" {...props} viewBox="0 0 24 24">
      <circle cx="4" cy="12" r="3" fill="currentColor">
        <animate
          id="svgSpinners3DotsBounce0"
          attributeName="cy"
          begin="0;svgSpinners3DotsBounce1.end+0.25s"
          calcMode="spline"
          dur="0.6s"
          keySplines=".33,.66,.66,1;.33,0,.66,.33"
          values="12;6;12"
        />
      </circle>
      <circle cx="12" cy="12" r="3" fill="currentColor">
        <animate
          attributeName="cy"
          begin="svgSpinners3DotsBounce0.begin+0.1s"
          calcMode="spline"
          dur="0.6s"
          keySplines=".33,.66,.66,1;.33,0,.66,.33"
          values="12;6;12"
        />
      </circle>
      <circle cx="20" cy="12" r="3" fill="currentColor">
        <animate
          id="svgSpinners3DotsBounce1"
          attributeName="cy"
          begin="svgSpinners3DotsBounce0.begin+0.2s"
          calcMode="spline"
          dur="0.6s"
          keySplines=".33,.66,.66,1;.33,0,.66,.33"
          values="12;6;12"
        />
      </circle>
    </svg>
  );
};

const LeftButtonArrow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} aria-hidden="true" fill="none" viewBox="0 0 6 10">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 1 1 5l4 4"
      />
    </svg>
  );
};

const RightButtonArrow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 6 10"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m1 9 4-4-4-4"
      />
    </svg>
  );
};

const LoupePlus = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="33"
      height="33"
      {...props}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.125 17.875V22C15.125 22.3896 15.257 22.7164 15.521 22.9804C15.785 23.2444 16.1113 23.3759 16.5 23.375C16.8887 23.3741 17.2155 23.2421 17.4804 22.979C17.7453 22.7159 17.8768 22.3896 17.875 22V17.875H22C22.3896 17.875 22.7164 17.743 22.9804 17.479C23.2444 17.215 23.3759 16.8887 23.375 16.5C23.3741 16.1113 23.2421 15.785 22.979 15.521C22.7159 15.257 22.3896 15.125 22 15.125H17.875V11C17.875 10.6104 17.743 10.2841 17.479 10.021C17.215 9.75792 16.8887 9.62592 16.5 9.625C16.1113 9.62408 15.785 9.75608 15.521 10.021C15.257 10.2859 15.125 10.6122 15.125 11V15.125H11C10.6104 15.125 10.2841 15.257 10.021 15.521C9.75792 15.785 9.62592 16.1113 9.625 16.5C9.62409 16.8887 9.75609 17.2155 10.021 17.4804C10.2859 17.7453 10.6123 17.8768 11 17.875H15.125ZM16.5 30.25C14.5979 30.25 12.8104 29.8888 11.1375 29.1665C9.46459 28.4442 8.00938 27.4647 6.77188 26.2281C5.53438 24.9915 4.55492 23.5363 3.8335 21.8625C3.11209 20.1887 2.75092 18.4012 2.75 16.5C2.74909 14.5988 3.11025 12.8113 3.8335 11.1375C4.55675 9.46367 5.53621 8.00846 6.77188 6.77188C8.00754 5.53529 9.46275 4.55583 11.1375 3.8335C12.8123 3.11117 14.5998 2.75 16.5 2.75C18.4003 2.75 20.1877 3.11117 21.8625 3.8335C23.5373 4.55583 24.9925 5.53529 26.2281 6.77188C27.4638 8.00846 28.4437 9.46367 29.1679 11.1375C29.892 12.8113 30.2528 14.5988 30.25 16.5V27.5C30.25 28.2563 29.981 28.9039 29.4429 29.4429C28.9048 29.9819 28.2572 30.2509 27.5 30.25H16.5ZM16.5 27.5C19.5708 27.5 22.1719 26.4344 24.3031 24.3031C26.4344 22.1719 27.5 19.5708 27.5 16.5C27.5 13.4292 26.4344 10.8281 24.3031 8.69687C22.1719 6.56562 19.5708 5.5 16.5 5.5C13.4292 5.5 10.8281 6.56562 8.69688 8.69687C6.56563 10.8281 5.5 13.4292 5.5 16.5C5.5 19.5708 6.56563 22.1719 8.69688 24.3031C10.8281 26.4344 13.4292 27.5 16.5 27.5Z"
        fill="white"
      />
    </svg>
  );
};

const LoupeMinus = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="33"
      height="33"
      {...props}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 30.25C14.5979 30.25 12.8104 29.8888 11.1375 29.1665C9.46459 28.4442 8.00938 27.4647 6.77188 26.2281C5.53438 24.9915 4.55492 23.5363 3.8335 21.8625C3.11209 20.1887 2.75092 18.4012 2.75 16.5C2.74909 14.5988 3.11025 12.8113 3.8335 11.1375C4.55675 9.46367 5.53621 8.00846 6.77188 6.77188C8.00754 5.53529 9.46275 4.55583 11.1375 3.8335C12.8123 3.11117 14.5998 2.75 16.5 2.75C18.4003 2.75 20.1878 3.11117 21.8625 3.8335C23.5373 4.55583 24.9925 5.53529 26.2281 6.77188C27.4638 8.00846 28.4437 9.46367 29.1679 11.1375C29.892 12.8113 30.2528 14.5988 30.25 16.5V27.5C30.25 28.2563 29.981 28.9039 29.4429 29.4429C28.9048 29.9819 28.2572 30.2509 27.5 30.25H16.5ZM16.5 27.5C19.5708 27.5 22.1719 26.4344 24.3031 24.3031C26.4344 22.1719 27.5 19.5708 27.5 16.5C27.5 13.4292 26.4344 10.8281 24.3031 8.69687C22.1719 6.56562 19.5708 5.5 16.5 5.5C13.4292 5.5 10.8281 6.56562 8.69688 8.69687C6.56563 10.8281 5.5 13.4292 5.5 16.5C5.5 19.5708 6.56563 22.1719 8.69688 24.3031C10.8281 26.4344 13.4292 27.5 16.5 27.5Z"
        fill="white"
      />
      <path
        d="M22 17.875C22.3896 17.875 22.7164 17.743 22.9804 17.479C23.2444 17.215 23.3759 16.8887 23.375 16.5C23.3741 16.1113 23.2421 15.785 22.979 15.521C22.7159 15.257 22.3896 15.125 22 15.125H11C10.6104 15.125 10.2841 15.257 10.021 15.521C9.75792 15.785 9.62592 16.1113 9.625 16.5C9.62409 16.8887 9.75609 17.2155 10.021 17.4804C10.2859 17.7453 10.6123 17.8768 11 17.875H22Z"
        fill="white"
      />
    </svg>
  );
};

const Phone = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_159_219)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.552 22.133C15.112 22.08 11.031 21.516 6.757 17.243C2.484 12.969 1.921 8.889 1.867 7.448C1.787 5.252 3.469 3.119 5.412 2.286C5.64598 2.18497 5.9022 2.1465 6.15553 2.17438C6.40886 2.20225 6.65059 2.29551 6.857 2.445C8.457 3.611 9.561 5.375 10.509 6.762C10.7176 7.06673 10.8068 7.43755 10.7596 7.8038C10.7123 8.17005 10.532 8.50613 10.253 8.748L8.302 10.197C8.20774 10.2651 8.14139 10.365 8.11528 10.4783C8.08916 10.5916 8.10505 10.7105 8.16 10.813C8.602 11.616 9.388 12.812 10.288 13.712C11.188 14.612 12.441 15.45 13.3 15.942C13.4077 16.0025 13.5345 16.0194 13.6543 15.9892C13.774 15.9591 13.8777 15.8842 13.944 15.78L15.214 13.847C15.4475 13.5368 15.7919 13.3291 16.1752 13.2672C16.5584 13.2053 16.9508 13.2941 17.27 13.515C18.677 14.489 20.319 15.574 21.521 17.113C21.6826 17.3209 21.7854 17.5684 21.8187 17.8296C21.8519 18.0909 21.8144 18.3562 21.71 18.598C20.873 20.551 18.755 22.214 16.552 22.133Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_159_219">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Burger = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="16"
      {...props}
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33333 16H22.6667C23.4 16 24 15.4 24 14.6667C24 13.9333 23.4 13.3333 22.6667 13.3333H1.33333C0.6 13.3333 0 13.9333 0 14.6667C0 15.4 0.6 16 1.33333 16ZM1.33333 9.33333H22.6667C23.4 9.33333 24 8.73333 24 8C24 7.26667 23.4 6.66667 22.6667 6.66667H1.33333C0.6 6.66667 0 7.26667 0 8C0 8.73333 0.6 9.33333 1.33333 9.33333ZM0 1.33333C0 2.06667 0.6 2.66667 1.33333 2.66667H22.6667C23.4 2.66667 24 2.06667 24 1.33333C24 0.6 23.4 0 22.6667 0H1.33333C0.6 0 0 0.6 0 1.33333Z"
        fill="currentColor"
      />
    </svg>
  );
};

const Close = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="14"
      height="14"
      {...props}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3002 0.70998C13.2077 0.617276 13.0978 0.543728 12.9768 0.493547C12.8559 0.443366 12.7262 0.417535 12.5952 0.417535C12.4643 0.417535 12.3346 0.443366 12.2136 0.493547C12.0926 0.543728 11.9827 0.617276 11.8902 0.70998L7.00022 5.58998L2.11022 0.699979C2.01764 0.607397 1.90773 0.533957 1.78677 0.483852C1.6658 0.433747 1.53615 0.407959 1.40522 0.407959C1.27429 0.407959 1.14464 0.433747 1.02368 0.483852C0.902716 0.533957 0.792805 0.607397 0.700223 0.699979C0.607642 0.792561 0.534202 0.902472 0.484097 1.02344C0.433992 1.1444 0.408203 1.27405 0.408203 1.40498C0.408203 1.53591 0.433992 1.66556 0.484097 1.78652C0.534202 1.90749 0.607642 2.0174 0.700223 2.10998L5.59022 6.99998L0.700223 11.89C0.607642 11.9826 0.534202 12.0925 0.484097 12.2134C0.433992 12.3344 0.408203 12.464 0.408203 12.595C0.408203 12.7259 0.433992 12.8556 0.484097 12.9765C0.534202 13.0975 0.607642 13.2074 0.700223 13.3C0.792805 13.3926 0.902716 13.466 1.02368 13.5161C1.14464 13.5662 1.27429 13.592 1.40522 13.592C1.53615 13.592 1.6658 13.5662 1.78677 13.5161C1.90773 13.466 2.01764 13.3926 2.11022 13.3L7.00022 8.40998L11.8902 13.3C11.9828 13.3926 12.0927 13.466 12.2137 13.5161C12.3346 13.5662 12.4643 13.592 12.5952 13.592C12.7262 13.592 12.8558 13.5662 12.9768 13.5161C13.0977 13.466 13.2076 13.3926 13.3002 13.3C13.3928 13.2074 13.4662 13.0975 13.5163 12.9765C13.5665 12.8556 13.5922 12.7259 13.5922 12.595C13.5922 12.464 13.5665 12.3344 13.5163 12.2134C13.4662 12.0925 13.3928 11.9826 13.3002 11.89L8.41022 6.99998L13.3002 2.10998C13.6802 1.72998 13.6802 1.08998 13.3002 0.70998Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Icons = {
  ArrowRight,
  Email,
  Location,
  InfiniteLoadingDotsIcon,
  LeftButtonArrow,
  RightButtonArrow,
  LoupePlus,
  LoupeMinus,
  Phone,
  Burger,
  Close
};

/* eslint-disable no-unused-vars */
export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GOOGLE_GA_ID!;

type EventParams = {
  action: (string & {}) | Gtag.EventNames;
  url?: string;
  label?: string;
  value?: string | number;
  event_label?: string;
  non_interaction?: boolean;
};

type CallbackFunction<T> = (params: T) => void;

const withWindowCheck = <T>(callback: CallbackFunction<T>): CallbackFunction<T> => {
  return (params: T) => {
    if (typeof window !== 'undefined') {
      callback(params);
    }
  };
};

export const sendCustomEvent = withWindowCheck<EventParams>(({ action, url, ...rest }) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: action,
    timeStamp: new Date().toUTCString(),
    url: url,
    ...rest
  });
});

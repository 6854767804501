type ContainerProps = {
  children: React.ReactNode;
  maxWidth?: '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl';
};

export default function Container({ children, maxWidth = '2xl' }: ContainerProps) {
  return (
    <section
      className={`container max-w-${maxWidth} m-auto space-y-4 px-4 text-center lg:space-y-10`}
    >
      {children}
    </section>
  );
}

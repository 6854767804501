'use client';

import { sendCustomEvent } from '@/gtag';

import HiddenLink from '../HiddenLink';

const url = new URL(
  process.env.NODE_ENV === 'development' ? 'http://localhost:3000/' : 'https://www.lapki-pets.by/'
);

export default function CallToNumber({
  phoneNo,
  path
}: {
  phoneNo: string | undefined;
  path: string | undefined;
}) {
  return (
    <HiddenLink>
      <a
        className="text-lg text-blue-600 underline dark:text-yellow-300 sm:text-base"
        href={`tel:${phoneNo}`}
        onClick={() => {
          sendCustomEvent({
            action: 'click_call',
            url: `${url}/${path}`
          });
        }}
      >
        {phoneNo}
      </a>
    </HiddenLink>
  );
}

import { useTheme } from 'next-themes';
import { useCallback, useEffect, useState } from 'react';

export default function useThemeSwitch() {
  const [mounted, setMounted] = useState(false);
  const { setTheme, resolvedTheme: theme } = useTheme();

  const [enabled, setEnabled] = useState(theme == 'light');

  const handleThemeChange = useCallback(
    (enabled: boolean) => {
      setTheme(enabled ? 'light' : 'dark');
      setEnabled(enabled);
    },
    [setTheme]
  );

  useEffect(() => setMounted(true), []);

  return {
    mounted,
    enabled,
    handleThemeChange
  };
}

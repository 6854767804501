'use client';

import { useUser } from '@auth0/nextjs-auth0/client';
import { useEffect, useState } from 'react';

import { Icons } from '../ui';

export default function HiddenLink({ children }: { children: React.ReactNode }) {
  const [showPhone, setShowPhone] = useState(false);

  const { user, isLoading } = useUser();

  const handleShowPhone = () => {
    setShowPhone(true);
  };

  useEffect(() => {
    if (!isLoading) {
      setShowPhone(!!user);
    }
  }, [isLoading, user]);

  if (isLoading) {
    return <Icons.InfiniteLoadingDotsIcon height={20} width={60} />;
  }

  return (
    <>
      {showPhone ? (
        children
      ) : (
        <button
          onClick={handleShowPhone}
          className="font-semibold text-blue-600 underline hover:text-blue-800 dark:text-yellow-300"
        >
          Показать
        </button>
      )}
    </>
  );
}
